import {
  where,
  query,
  writeBatch,
  getDoc,
  Firestore,
  WriteBatch,
} from "firebase/firestore";

import { FirebaseController } from "database/FirebaseController";
import {
  getDefaultGallery,
  getDefaultGalleryDeviceSettings,
  getDefaultDeviceIntervalSettings,
  getDefaultDeviceSettings,
} from "database/DataDefaultValues";
import {
  GalleryV2,
  GalleryDeviceV2Settings,
  IntervalV2,
  DeviceV2IntervalSettings,
  Client,
  DeviceV2,
  DeviceV2Settings,
} from "database/DataTypes";

import { getFieldNames } from "utils/form";
import _ from "lodash";
import { getDeviceCol } from "./_DeviceController";

type GetGalleriesOptions = {
  parentId?: number;
  isActive?: boolean;
  isArchived?: boolean;
  isVisible?: boolean;
  isWithSubFrame?: boolean;
  jobSiteIds?: (number | null)[];
};

type AddGalleryOptions = {
  device?: Partial<DeviceV2>;
  deviceSettings?: Partial<DeviceV2Settings>;
};

type UpdateGalleryOptions = {
  gallery?: Partial<GalleryV2>;
  gallerySettings?: Partial<GalleryDeviceV2Settings>;
  galleryIntervalSettings?: Partial<DeviceV2IntervalSettings>;
  currentAssignedDeviceId?: string | number | null;
  device?: Partial<DeviceV2>;
  deviceSettings?: Partial<DeviceV2Settings>;
};

export class _GalleryController {
  private parent!: FirebaseController;
  private db!: Firestore;

  galleryList!: GalleryV2[];

  constructor(parent: FirebaseController) {
    this.parent = parent;
    this.db = this.parent.getDb();
    this.galleryList = [];
  }

  async getGallery(galleryId) {
    return this.parent.getDocumentWithId<GalleryV2>("galleries", galleryId);
  }

  async getGalleries(options: GetGalleriesOptions = {}) {
    let galleryList: GalleryV2[] = [];

    const isWithSubFrame = options.isWithSubFrame ?? true;

    const queries = [
      ...(!_.isUndefined(options.parentId)
        ? [where("parentId", "==", options.parentId)]
        : []),
      ...(!_.isUndefined(options.isActive)
        ? [where("active", "==", options.isActive)]
        : []),
      ...(!_.isUndefined(options.isArchived)
        ? [where("archived", "==", options.isArchived)]
        : []),
      ...(!_.isUndefined(options.isVisible)
        ? [where("visible", "==", options.isVisible)]
        : []),
      ...(isWithSubFrame ? [] : [where("isSubFrame", "!=", true)]),
    ];

    if (options.jobSiteIds) {
      if (options.jobSiteIds.length > 0) {
        const promises: Promise<GalleryV2[]>[] = [];

        const idsChuck = _.chunk(options.jobSiteIds, 30);

        idsChuck.forEach((ids) => {
          promises.push(
            this.parent.getDocumentListWithQuery<GalleryV2>(
              query(
                this.parent.getColRef("galleries"),
                ...queries,
                where("jobSite", "in", ids),
              ),
            ),
          );
        });

        await Promise.all(promises).then((data) => {
          galleryList = data.flat();
        });
      } else {
        galleryList = [];
      }
    } else {
      galleryList = await this.parent.getDocumentListWithQuery<GalleryV2>(
        query(this.parent.getColRef("galleries"), ...queries),
      );
    }

    this.galleryList = galleryList;

    return galleryList;
  }

  async getGallerySettings(id) {
    return this.parent.getDocumentWithId<GalleryDeviceV2Settings>(
      `galleries/${id}/GallerySettings`,
      "Settings",
    );
  }

  async getGalleryIntervals(id) {
    const intervals = (await this.parent.getDocumentHash<IntervalV2>(
      `galleries/${id}/GalleryIntervalSettings`,
    )) as DeviceV2IntervalSettings;

    return intervals;
  }

  async addGallery(
    client: Client,
    gallery: Partial<GalleryV2> = {},
    gallerySettings: Partial<GalleryDeviceV2Settings> = {},
    galleryIntervalSettings: Partial<DeviceV2IntervalSettings> = {},
    { device, deviceSettings }: AddGalleryOptions = {},
  ) {
    // TODO: try make the code to be shared with updateGallery function

    if (!client) {
      throw new Error("client not found.");
    }

    const id = gallery.id || this.parent.getNewDocumentId();

    const newGallery = {
      ...getDefaultGallery(),
      ...gallery,
      id,
    };

    const newGallerySettings = {
      ...getDefaultGalleryDeviceSettings(),
      ...gallerySettings,
    };

    const newGalleryIntervalSettings = {
      ...getDefaultDeviceIntervalSettings(),
      ...galleryIntervalSettings,
    };

    const batch = writeBatch(this.db);

    const galleryRef = this.parent.getDocRef<GalleryV2>("galleries", id);
    const gallerySettingsRef = this.parent.getDocRef(
      `galleries/${id}/GallerySettings`,
      "Settings",
    );

    batch.set(galleryRef, newGallery);
    batch.set(gallerySettingsRef, newGallerySettings);

    let assignedDeviceId;
    let removedDeviceId;
    let previousGallery;

    // only update device if host is firebase
    const isUpdateDevice =
      newGallery.assignedDevice && newGallery.galleryImageHost === "0";

    if (isUpdateDevice) {
      // assigning new device, copy current gallery settings to it

      const deviceRef = this.parent.getDocRef<DeviceV2>(
        getDeviceCol(newGallery.assignedDevice),
        newGallery.assignedDevice,
      );
      const deviceSettingsRef = this.parent.getDocRef(
        `${getDeviceCol(newGallery.assignedDevice)}/${
          newGallery.assignedDevice
        }/DeviceSettings`,
        "Settings",
      );

      const deviceSnapshot = await getDoc(deviceRef);
      const assigningDevice = deviceSnapshot.data();

      if (!assigningDevice) {
        throw new Error("device not found.");
      }

      assignedDeviceId = newGallery.assignedDevice;

      if (assigningDevice.assignedGallery) {
        // if device alreayd has a gallery assigned, unklink it

        const existingGalleryRef = assigningDevice.assignedGallery;
        previousGallery = (await getDoc(existingGalleryRef)).data();

        batch.update(existingGalleryRef, { assignedDevice: null });

        removedDeviceId = assigningDevice.id;
      }

      const jobSite = await this.parent.JobSite.getJobSite(newGallery.jobSite);

      if (!jobSite) {
        throw new Error("job site not found.");
      }

      batch.update(deviceRef, {
        ...(device || {}),
        frontendName: `${client.name} / ${jobSite.name} / ${newGallery.galleryName}`,
        assignedGallery: galleryRef,
      });

      batch.update(deviceSettingsRef, {
        ...this._pickDeviceSettingsFromGallerySettings(newGallerySettings),
        ...(deviceSettings || {}),
      });
    }

    for (const day in newGalleryIntervalSettings) {
      const galleryIntervalSettingsRef = this.parent.getDocRef(
        `galleries/${galleryRef.id}/GalleryIntervalSettings`,
        day,
      );

      batch.set(galleryIntervalSettingsRef, newGalleryIntervalSettings[day]);

      if (assignedDeviceId) {
        const deviceIntervalSettingsRef = this.parent.getDocRef(
          `${getDeviceCol(
            assignedDeviceId,
          )}/${assignedDeviceId}/DeviceIntervalSettings`,
          day,
        );

        batch.set(deviceIntervalSettingsRef, newGalleryIntervalSettings[day]);
      }
    }

    return await batch.commit().then(async () => {
      if (assignedDeviceId) {
        if (removedDeviceId && previousGallery) {
          await this.addRemoveDeviceAdminEvent(
            removedDeviceId,
            previousGallery.galleryName,
            previousGallery.id,
          );
        }

        this.addAssignDeivceAdminEvent(
          assignedDeviceId,
          newGallery.galleryName,
          id.toString(),
        );
      }
    });
  }

  async addUpdateGalleryAdminEvent(deviceId, editedData) {
    const editedFieldsNames = getFieldNames(editedData);

    return await this.parent.Device.addDeviceAdminEvents({
      deviceId,
      eventTitle: "Gallery Settings Updated",
      eventDetails: `Updated fields are '${editedFieldsNames.join("', ")}'.`,
    });
  }

  async addAssignDeivceAdminEvent(deviceId, galleryName, galleryId) {
    return await this.parent.Device.addDeviceAdminEvents({
      deviceId,
      eventTitle: "Assigned to Gallery",
      eventDetails: `Gallery named ${galleryName}, with id ${galleryId}.`,
    });
  }

  async addRemoveDeviceAdminEvent(deviceId, galleryName, galleryId) {
    return await this.parent.Device.addDeviceAdminEvents({
      deviceId,
      eventTitle: "Removed from Gallery",
      eventDetails: `Gallery named ${galleryName}, with id ${galleryId}.`,
    });
  }

  async updateGallery(
    galleryId: string | number,
    client: Client,
    {
      gallery,
      gallerySettings,
      galleryIntervalSettings,
      currentAssignedDeviceId,
      device,
      deviceSettings,
    }: UpdateGalleryOptions,
  ) {
    // TODO: it might be better without using batch
    // might be easier to maintain if we update the gallery first and pull the data then update the device instead of doing it in a single batch transaction

    if (!client) {
      throw new Error("client not found.");
    }

    const batch = writeBatch(this.db);

    const galleryRef = this.parent.getDocRef<GalleryV2>("galleries", galleryId);

    if (gallery) {
      // update gallery

      batch.update(galleryRef, gallery);
    }

    if (gallerySettings) {
      // update gallery settings

      const gallerySettingsRef = this.parent.getDocRef(
        `galleries/${galleryRef.id}/GallerySettings`,
        "Settings",
      );

      batch.update(gallerySettingsRef, gallerySettings);
    }

    if (galleryIntervalSettings) {
      // update gallery intervals settings

      for (const day in galleryIntervalSettings) {
        const galleryIntervalSettingsRef = this.parent.getDocRef(
          `galleries/${galleryRef.id}/GalleryIntervalSettings`,
          day,
        );

        batch.update(galleryIntervalSettingsRef, galleryIntervalSettings[day]);
      }
    }

    // update assigned device if exist
    // if assignedDevice key not found means assignedDevice is not edited
    // assignedDevice will be null if removing current assignedDevice
    if (currentAssignedDeviceId || !_.isUndefined(gallery?.assignedDevice)) {
      await this._handleAssignedDevice(batch, galleryId, client, {
        gallery,
        gallerySettings,
        galleryIntervalSettings,
        currentAssignedDeviceId,
        device,
        deviceSettings,
      });
    }

    return await batch.commit();
  }

  private async _handleAssignedDevice(
    batch: WriteBatch,
    galleryId: string | number,
    client: Client,
    {
      gallery,
      gallerySettings,
      galleryIntervalSettings,
      currentAssignedDeviceId,
      device,
      deviceSettings,
    }: UpdateGalleryOptions,
  ) {
    const assignToDevice = async (deviceId) => {
      // link device to this gallery
      // and check if the device that we trying to assign has gallery assigned already, unlink it if yes

      const assignedDeviceRef = this.parent.getDocRef<DeviceV2>(
        getDeviceCol(deviceId),
        deviceId,
      );

      const deviceSnapshot = await getDoc(assignedDeviceRef);
      const assignedDevice = deviceSnapshot.data();

      if (!assignedDevice) {
        throw new Error("device not found.");
      }

      const prevGalleryRef = assignedDevice.assignedGallery;

      if (prevGalleryRef) {
        // unlink the device with its current gallery

        batch.update(prevGalleryRef, { assignedDevice: null });
      }

      await updateAssignedDevice(assignedDevice.id, true);
    };

    const removeDevice = async (deviceId) => {
      // update and unlink from the assigned device

      const devicesRef = this.parent.getDocRef(
        getDeviceCol(deviceId),
        deviceId,
      );

      batch.update(devicesRef, {
        frontendName: "",
        assignedGallery: null,
      });
    };

    const updateAssignedDevice = async (deviceId, isNewAssigned = false) => {
      // update latest info to currently assigned device

      const assignedDeviceRef = this.parent.getDocRef<DeviceV2>(
        getDeviceCol(deviceId),
        deviceId,
      );

      if (isNewAssigned) {
        // copy all settings from gallery to device

        const currentGallerySettings = await this.getGallerySettings(galleryId);
        const currentGalleryIntervalSettings = await this.getGalleryIntervals(
          galleryId,
        );

        const deviceSettings = {
          ...this._pickDeviceSettingsFromGallerySettings(
            currentGallerySettings || {},
          ),
        };

        const deviceSettingsRef = this.parent.getDocRef<DeviceV2Settings>(
          `${getDeviceCol(deviceId)}/${deviceId}/DeviceSettings`,
          "Settings",
        );

        batch.update(deviceSettingsRef, deviceSettings);

        for (const day in currentGalleryIntervalSettings) {
          const deviceIntervalSettingsRef =
            this.parent.getDocRef<DeviceV2IntervalSettings>(
              `${getDeviceCol(deviceId)}/${deviceId}/DeviceIntervalSettings`,
              day,
            );

          batch.update(
            deviceIntervalSettingsRef,
            currentGalleryIntervalSettings[day],
          );
        }
      }

      if (gallery?.galleryName || gallery?.jobSite) {
        // update device frontEnd Name and assignedGallery

        const jobSite = await this.parent.JobSite.getJobSite(gallery.jobSite);

        if (!jobSite) {
          throw new Error("job site not found.");
        }

        const galleryRef = this.parent.getDocRef("galleries", galleryId);

        batch.update(assignedDeviceRef, {
          frontendName: `${client.name} / ${jobSite.name} / ${gallery.galleryName}`,
          assignedGallery: galleryRef,
        });
      }

      if (gallerySettings || deviceSettings) {
        // copy changed settings to device

        const deviceSettingsRef = this.parent.getDocRef<DeviceV2Settings>(
          `${getDeviceCol(deviceId)}/${deviceId}/DeviceSettings`,
          "Settings",
        );

        const settings = {
          ...this._pickDeviceSettingsFromGallerySettings(gallerySettings || {}),
          ...(deviceSettings || {}),
        };

        batch.update(deviceSettingsRef, settings);
      }

      if (galleryIntervalSettings) {
        // copy interval settings to device

        for (const day in galleryIntervalSettings) {
          const deviceIntervalSettingsRef =
            this.parent.getDocRef<DeviceV2IntervalSettings>(
              `${getDeviceCol(deviceId)}/${deviceId}/DeviceIntervalSettings`,
              day,
            );

          batch.update(deviceIntervalSettingsRef, galleryIntervalSettings[day]);
        }
      }

      if (device) {
        const deviceRef = this.parent.getDocRef<DeviceV2>(
          getDeviceCol(deviceId),
          deviceId,
        );

        batch.update(deviceRef, device);
      }
    };

    if (gallery && "assignedDevice" in gallery) {
      if (currentAssignedDeviceId) {
        if (!gallery.assignedDevice) {
          // remove assigned device

          await removeDevice(currentAssignedDeviceId);
        } else if (gallery.assignedDevice !== currentAssignedDeviceId) {
          // change assigned device

          await removeDevice(currentAssignedDeviceId);
          await assignToDevice(gallery.assignedDevice);
        }
      } else {
        // assign device

        await assignToDevice(gallery.assignedDevice);
      }
    } else if (currentAssignedDeviceId) {
      // update current device

      await updateAssignedDevice(currentAssignedDeviceId);
    }
  }

  private _pickDeviceSettingsFromGallerySettings(
    gallerySettings: Partial<GalleryDeviceV2Settings>,
  ) {
    // pick only the field that existed in device settings from gallery settings

    const allowedSettings = _.pick(
      gallerySettings,
      ...Object.keys(getDefaultDeviceSettings()),
    );

    return allowedSettings;
  }

  async deleteGalleryDoc(galleryId) {
    return await this.parent.Callable.deleteDocRecursive({
      collection: "galleries",
      path: galleryId,
    })
      .then(({ status }) => {
        return status;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async deleteGallery(galleryId) {
    if (galleryId) {
      const gallery = await this.getGallery(galleryId);

      if (gallery) {
        if (gallery.assignedDevice) {
          await this.parent.createOrUpdateDocument(
            {
              frontendName: "",
              assignedGallery: null,
            },
            getDeviceCol(gallery.assignedDevice),
            gallery.assignedDevice,
          );
        }

        const subFrames = await this.getGalleries({
          parentId: gallery.id as number,
          isWithSubFrame: true,
        });

        const promises: Promise<any>[] = [this.deleteGalleryDoc(galleryId)];

        if (subFrames.length > 0) {
          subFrames.forEach((sub) => {
            promises.push(this.deleteGalleryDoc(sub.id as number));
          });
        }

        return await Promise.all(promises)
          .then(() => {
            return true;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }
}
