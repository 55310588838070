import {
  writeBatch,
  arrayUnion,
  getDocs,
  query,
  where,
  arrayRemove,
  Firestore,
} from "firebase/firestore";

import {
  CreateOrUpdateDocumentOptions,
  FirebaseController,
  getFirebaseController,
} from "database/FirebaseController";

import { getDefaultJobSite } from "database/DataDefaultValues";
import { JobSite, Client, UserRole, GalleryV2 } from "database/DataTypes";
import _ from "lodash";
import { promise } from "zod";

type AddJobSiteV2Options = {
  userRoleIds?: number[];
} & CreateOrUpdateDocumentOptions;

type GetJobSitesOptions = {
  ids?: number[];
  isArchived?: boolean;
};

export class _JobSiteController {
  private parent!: FirebaseController;
  private db!: Firestore;

  constructor(parent: FirebaseController) {
    this.parent = parent;
    this.db = this.parent.getDb();
  }

  async getJobSite(jobSiteId) {
    return this.parent.getDocumentWithId<JobSite>("jobSites", jobSiteId);
  }

  async getJobSites(options: GetJobSitesOptions = {}) {
    let jobSiteList: JobSite[] = [];

    const queries = [
      ...(!_.isUndefined(options.isArchived)
        ? [where("archived", "==", options.isArchived)]
        : []),
    ];

    if (options.ids) {
      if (options.ids.length > 0) {
        const promises: Promise<JobSite[]>[] = [];

        const idsChuck = _.chunk(options.ids, 30);

        idsChuck.forEach((ids) => {
          promises.push(
            this.parent.getDocumentListWithQuery<JobSite>(
              query(
                this.parent.getColRef("jobSites"),
                ...queries,
                where("id", "in", ids),
              ),
            ),
          );
        });

        await Promise.all(promises).then((data) => {
          jobSiteList = data.flat();
        });
      } else {
        jobSiteList = [];
      }
    } else {
      jobSiteList = await this.parent.getDocumentListWithQuery<JobSite>(
        query(this.parent.getColRef("jobSites"), ...queries),
      );
    }

    return jobSiteList;
  }

  async addJobSiteV2(
    jobSite: Partial<JobSite>,
    { userRoleIds, returnDocument = false }: AddJobSiteV2Options = {},
  ) {
    const batch = writeBatch(this.db);

    const jobSiteId = jobSite.id || this.parent.getNewDocumentId();

    const jobSiteRef = this.parent.getDocRef<JobSite>("jobSites", jobSiteId);

    const newJobSite: JobSite = {
      ...getDefaultJobSite(),
      ...jobSite,
      id: jobSiteId,
    };

    batch.set(jobSiteRef, newJobSite);

    if (jobSite.associatedClients && jobSite.associatedClients.length > 0) {
      const inheritedUserRoles =
        await this.parent.getDocumentListWithQuery<UserRole>(
          query(
            this.parent.getColRef<UserRole>("userRoles"),
            where("isInheritSites", "==", true),
            where("associatedClient", "in", jobSite.associatedClients),
          ),
        );

      if (inheritedUserRoles.length > 0) {
        inheritedUserRoles.forEach((userRole) => {
          const userRoleRef = this.parent.getDocRef<UserRole>(
            "userRoles",
            userRole.id,
          );

          batch.update(userRoleRef, {
            accessableJobSites: arrayUnion(jobSiteId),
          });
        });
      }

      jobSite.associatedClients.forEach((clientId) => {
        const clientRef = this.parent.getDocRef<Client>("clients", clientId);

        batch.update(clientRef, {
          sites: arrayUnion(jobSiteId),
        });
      }, []);
    }

    // only users under corret client will be pass in
    if (userRoleIds && userRoleIds.length > 0) {
      userRoleIds.forEach((userRoleId) => {
        const userRoleRef = this.parent.getDocRef<UserRole>(
          "userRoles",
          userRoleId,
        );

        batch.update(userRoleRef, {
          accessableJobSites: arrayUnion(jobSiteId),
        });
      });
    }

    return await batch.commit().then(() => {
      if (returnDocument) {
        return this.getJobSite(jobSiteId);
      }
    });
  }

  // deprecating, use addJobSiteV2
  async addJobSite(
    jobSite: Partial<JobSite>,
    isAddToClient: boolean,
    isAddToUsers: boolean,
  ) {
    const batch = writeBatch(this.db);

    const jobSiteId = jobSite.id || this.parent.getNewDocumentId();

    const jobSiteRef = this.parent.getDocRef<JobSite>("jobSites", jobSiteId);

    const newJobSite: JobSite = {
      ...getDefaultJobSite(),
      ...jobSite,
      id: jobSiteId,
    };

    batch.set(jobSiteRef, newJobSite);

    if (isAddToClient) {
      const clientId = newJobSite.associatedClients[0];

      const clientRef = this.parent.getDocRef<Client>("clients", clientId);

      batch.update(clientRef, {
        sites: arrayUnion(jobSiteId),
      });

      if (isAddToUsers) {
        const userRolesSnaphot = await getDocs(
          query(
            this.parent.getColRef<UserRole>("userRoles"),
            where("associatedClient", "==", clientId),
          ),
        );

        userRolesSnaphot.forEach((snap) => {
          const userRole = snap.data();

          const userRoleRef = this.parent.getDocRef<UserRole>(
            "userRoles",
            userRole.id,
          );

          batch.update(userRoleRef, {
            accessableJobSites: arrayUnion(jobSiteId),
          });
        });
      }
    }

    return await batch.commit();
  }

  async updateJobSite(
    jobSiteId: number,
    jobSite: Partial<JobSite>,
    editedUserRoleIds: number[][],
    editedClientIds: number[][],
    { returnDocument = false }: CreateOrUpdateDocumentOptions = {},
  ) {
    const batch = writeBatch(this.db);

    const jobSiteRef = this.parent.getDocRef<JobSite>("jobSites", jobSiteId);

    if (jobSite) {
      batch.update(jobSiteRef, jobSite);

      if (jobSite.archived) {
        await getFirebaseController()
          .Gallery.getGalleries({
            jobSiteIds: [jobSiteId],
            isWithSubFrame: true,
          })
          .then((galleries) => {
            galleries.forEach((gallery) => {
              const galleryRef = this.parent.getDocRef<GalleryV2>(
                "galleries",
                gallery.id,
              );

              batch.update(galleryRef, {
                active: false,
                archived: true,
              });
            });
          });
      }
    }

    const [addedRoleIds = [], removedRoleIds = []] = editedUserRoleIds;

    [addedRoleIds, removedRoleIds].forEach((ids, index) => {
      const isAdding = index === 0;

      ids.forEach((id) => {
        const userRoleRef = this.parent.getDocRef<UserRole>("userRoles", id);

        batch.update(userRoleRef, {
          accessableJobSites: isAdding
            ? arrayUnion(jobSiteId)
            : arrayRemove(jobSiteId),
        });
      });
    });

    const [addedClientIds = [], removedClientIds = []] = editedClientIds;

    [addedClientIds, removedClientIds].forEach((ids, index) => {
      const isAdding = index === 0;

      ids.forEach((id) => {
        const userRoleRef = this.parent.getDocRef<Client>("clients", id);

        batch.update(userRoleRef, {
          sites: isAdding ? arrayUnion(jobSiteId) : arrayRemove(jobSiteId),
        });
      });
    });

    return await batch.commit().then(() => {
      if (returnDocument) {
        return this.getJobSite(jobSiteId);
      }
    });
  }

  async deleteJobSite(jobSiteId: number) {
    const siteGalleries = await this.parent.Gallery.getGalleries({
      jobSiteIds: [jobSiteId],
      isWithSubFrame: true,
    });

    if (siteGalleries.length === 0) {
      const clients = await this.parent.Client.getClients({
        jobSiteIds: [jobSiteId],
      });
      const userRoles = await this.parent.User.getUserRoles({
        jobSiteIds: [jobSiteId],
      });

      const promises: Promise<any>[] = [];

      clients.forEach((client) => {
        promises.push(
          this.parent.Client.updateClient(client.id as number, {
            sites: client.sites.filter((id) => id !== jobSiteId),
          }),
        );
      });

      userRoles.forEach((role) => {
        promises.push(
          this.parent.User.updateUserRole(role.id as number, {
            accessableJobSites: role.accessableJobSites.filter(
              (id) => id !== jobSiteId,
            ),
          }),
        );
      });

      promises.push(
        this.parent
          .deleteDocument("jobSites", jobSiteId)
          .then(() => true)
          .catch((err) => {
            console.error(err);

            return false;
          }),
      );

      return await Promise.all(promises)
        .then(() => true)
        .catch((err) => {
          console.error(err);

          return false;
        });
    } else {
      return false;
    }
  }
}
