import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Autocomplete,
  Button,
  Divider,
  alpha,
  Chip,
  IconButton,
} from "@mui/material";

import {
  PrefixTextField,
  SliderInput,
  getDirtyFields,
  intervalSettingsColumns,
} from "Windows/Device/DeviceEditWindow";
import SettingsTable, {
  columns,
  SettingRow,
  IntervalSettingsRow,
  SettingLink,
  SettingConfirmationModal,
  SettingConfirmationModalProps,
  CopyLinkButton,
  SettingsTableHeaderButton,
  Column,
  SubFramesRows,
} from "components/Dashboard/SettingsTable";
import Navbar from "components/Layouts/Navbar";
import ConfirmationModal from "components/BaseModal/ConfirmationModal";
import DashboardEditContainer, {
  baseSettingAutocompleteProps,
  baseSettingSwitchProps,
  baseSettingTextFieldProps,
} from "components/Dashboard/DashboardEditContainer";

import {
  DAY_NAMES,
  INTERVAL_TIME_OPTIONS,
  INTERVAL_OPTIONS,
  BURST_INTERVAL_OPTIONS,
  getDefaultGallery,
  IMAGE_HOST_MAPPER,
  getDefaultDeviceIntervalSettings,
  ROTATE_VALUE_MAPPER,
  getDefaultGalleryDeviceSettings,
  AUTO_WHITE_BALANCE_MODE_NAME_MAPEPR,
  BRIGHTNESS_RANGE,
  CONTRAST_RANGE,
  SATURATION_RANGE,
  SHARPNESS_RANGE,
  UPDATE_CHANNEL_MAPPER,
  UPDATE_TIME_OPTIONS,
  IMAGE_HOST_DIRECTORY_OPTIONS_MAPPER,
  ROLE_ACCESS_LEVEL_MAPPER,
} from "database/DataDefaultValues";
import {
  Client,
  DeviceV2,
  DeviceV2Details,
  DeviceV2Settings,
  GalleryV2,
  User,
  UserRole,
} from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";

import { useAtomValue } from "jotai";
import { currentClientState } from "states/auth";
import { clientListCacheState, jobSitesListCacheState } from "states/caches";
import { _jobSiteCaches } from "./GalleryGridWindow";

import { Controller, useForm, UseFormRegister } from "react-hook-form";
import { useSnackbar } from "context/Snackbar/SnackbarContext";

import { useGalleryTracker } from "hooks/eventTracker/useGalleryTracker";
import useAccess from "hooks/useAccess";

import _ from "lodash";
import { getAdressAutoComplete, getGeocode } from "hooks/useGeoapify";
import Map from "components/Map/Map";

import DataChips from "components/Dashboard/DataChips";
import { conditionalPush, orderByIgnoreCase } from "utils/display";

import { SelectOption } from "utils/input";
import { OpenInNew, Link as LinkIcon, Settings } from "@mui/icons-material";

import LinkWrapper from "components/Wrapper/Link";

import { getContrastShade } from "theme/reliveItTheme";
import { getDifferences } from "utils/form";

import ReactCrop, {
  clamp,
  convertToPercentCrop,
  convertToPixelCrop,
  Crop,
} from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";

import ImageHandlerV2 from "database/ImageHandlerV2";
import { getDropboxController } from "database/DropboxController";

import pLimit from "p-limit";
import PhotoSentielController from "database/PhotoSentinelController";

import { roundCropPosition } from "utils/crop";
import LDSRingLoader from "components/Loaders/LDSRingLoader";

const limit = pLimit(30);

export const subFramesColumns: Column[] = [
  { id: "name", label: "Name", width: "10%" },
  { id: "frame", label: "Frame", width: "20%" },
  { id: "users", label: "Accessible User", width: "20%" },
  { id: "actions", label: "Actions", align: "center", width: "5%" },
];

const handleEditGalleryAdminEvents = (
  initGallery: GalleryV2,
  editedGallery: Partial<GalleryV2> = {},
  initEditedData: any,
  galleryName: string,
) => {
  const firebaseController = getFirebaseController();

  // TODO: refactor, might move this into gallery controller
  // TODO: better conditional checking

  if (
    (initGallery!.assignedDevice &&
      editedGallery.assignedDevice === undefined) ||
    initGallery!.assignedDevice === editedGallery.assignedDevice
  ) {
    // previosuly assigned device

    firebaseController.Gallery.addUpdateGalleryAdminEvent(
      initGallery.assignedDevice,
      initEditedData,
    );
  } else if (
    initGallery!.assignedDevice &&
    editedGallery.assignedDevice === null
  ) {
    // removing current assigned device

    firebaseController.Gallery.addRemoveDeviceAdminEvent(
      initGallery!.assignedDevice,
      galleryName,
      initGallery.id,
    );
  } else if (!initGallery!.assignedDevice && editedGallery.assignedDevice) {
    // assigning new device

    firebaseController.Gallery.addAssignDeivceAdminEvent(
      editedGallery.assignedDevice,
      galleryName,
      initGallery.id,
    );
  } else if (
    initGallery.assignedDevice &&
    initGallery!.assignedDevice !== editedGallery.assignedDevice
  ) {
    // change assigning device to new device

    firebaseController.Gallery.addRemoveDeviceAdminEvent(
      initGallery!.assignedDevice,
      galleryName,
      initGallery.id,
    );

    firebaseController.Gallery.addAssignDeivceAdminEvent(
      editedGallery.assignedDevice,
      galleryName,
      initGallery.id,
    );
  }
};

const _deviceSettingsCaches: { [key: string]: DeviceV2Settings } = {};
const _deviceDetailsCaches: { [key: string]: DeviceV2Details } = {};
const _deviceCaches: { [key: string]: DeviceV2 } = {};

const getGalleryThumbnail = async (
  gallery: GalleryV2,
  reload: boolean = false,
  isCrop: boolean = false,
): Promise<string> => {
  switch (gallery.galleryImageHost) {
    case "1": {
      const dropboxController = getDropboxController();

      return await limit(() => {
        return dropboxController
          .getLastImage(gallery.externalHostDirectory, {
            reload,
            objectId: gallery.id as number,
            proxy: gallery.useProxyApi,
          })
          .then(async (image) => {
            if (image) {
              return await dropboxController
                .getImageThumbnail(image.path_display, {
                  proxy: gallery.useProxyApi,
                })
                .then(async (rawUrl) => {
                  const { url } = await ImageHandlerV2.handleSubFrame(
                    gallery,
                    rawUrl,
                    !isCrop,
                  );

                  return url;
                });
            } else {
              return "";
            }
          })
          .catch((err) => {
            console.error(err);

            return "";
          });
      });
    }
    case "2": {
      return await PhotoSentielController.getFirstAndLastImage(
        gallery.assignedDevice,
      )
        .then((data) => {
          return data[0].thumb_url;
        })
        .catch((err) => {
          console.error(err);
          return "";
        });
    }
    case "0":
    default: {
      const firebaseController = getFirebaseController();

      return await firebaseController.Image.getLastImage(
        (gallery.isSubFrame ? gallery.parentId : gallery.id) as number,
        false,
      )
        .then(async (data) => {
          if (data && data.displayImage) {
            const image = data.displayImage;

            return await firebaseController.Image.getThumbnail(
              image.storageLocation,
              image.fileName,
            ).then(async (resultURL) => {
              const rawUrl = resultURL || image.url;

              const { url } = await ImageHandlerV2.handleSubFrame(
                gallery,
                rawUrl,
                !isCrop,
              );

              return url;
            });
          } else {
            return "";
          }
        })
        .catch((err) => {
          console.error(err);
          return "";
        });
    }
  }
};

const SubFrameGalleryThumbnail = ({ gallery }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState("");

  const init = async (gallery) => {
    setIsLoading(true);

    await getGalleryThumbnail(gallery, false, true).then((url) => {
      setImageSrc(url);
    });

    setIsLoading(false);
  };

  useEffect(() => {
    init(gallery);
  }, [gallery]);

  return (
    <Box sx={{ height: 200 }}>
      {isLoading ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LDSRingLoader />
        </Box>
      ) : imageSrc ? (
        <Box
          crossOrigin="anonymous"
          id="img"
          sx={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            userSelect: "none",
            pointerEvents: "none",
          }}
          component={"img"}
          src={imageSrc}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

const CropInput = ({
  register,
  field,
  onBlur,
  disabled = false,
}: {
  field: "x" | "y" | "width" | "height";
  register: UseFormRegister<any>;
  onBlur: (e, field: string, type: "px" | "%") => void;
  disabled?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography sx={{ opacity: disabled ? 0.6 : 1 }}>Pixel:</Typography>

        <TextField
          {...baseSettingTextFieldProps}
          {...register(`temp._pixelCropPosition.${field}`, {
            valueAsNumber: true,
          })}
          type="number"
          sx={{ width: 100 }}
          disabled={disabled}
          onBlur={(e) => onBlur(e, field, "px")}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography sx={{ opacity: disabled ? 0.6 : 1 }}>Percent:</Typography>

        <TextField
          {...baseSettingTextFieldProps}
          {...register(`gallery.cropPosition.${field}`, {
            valueAsNumber: true,
          })}
          type="number"
          sx={{ width: 100 }}
          disabled={disabled}
          onBlur={(e) => onBlur(e, field, "%")}
        />
      </Box>
    </Box>
  );
};

const GalleryEditWindow = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [initGallery, setInitGallery] = useState<GalleryV2 | null>(null);
  const [parentGallery, setParentGallery] = useState<GalleryV2 | null>(null);
  const [subFramesGalleries, setSubFramesGalleries] = useState<GalleryV2[]>([]);

  const [userList, setUserList] = useState<User[]>([]);
  const [galleryList, setGalleryList] = useState<GalleryV2[]>([]);
  const [deviceList, setDeviceList] = useState<DeviceV2[]>([]);
  const [currentClient, setCurrentClient] = useState<Client | null>(null);

  const currentUserClient = useAtomValue(currentClientState);
  const clientList = useAtomValue(clientListCacheState);
  const jobSiteList = useAtomValue(jobSitesListCacheState);

  const [tempAssignedDeviceId, setTempAssignedDeviceId] =
    useState<DeviceV2["id"]>(null);
  const [galleryName, setGalleryName] = useState<string>("");
  const [galleryImageHost, setGalleryImageHost] = useState<string>("0");
  const [intervalSettingsRenderTrigger, setIntervalSettingsRenderTrigger] =
    useState(false);
  const [deviceSettingsRenderTrigger, setDeviceSettingsRenderTrigger] =
    useState(false);

  const [isForcedFocus, setIsForcedFocus] = useState(false);
  const [isShowDeviceSettings, setIsShowDeviceSettings] = useState(false);

  const [galleryRemoveModalParams, setGalleryRemoveModalParams] = useState<{
    isOpen: boolean;
  }>({
    isOpen: false,
  });

  const [confirmationModalParams, setConfirmationModalParams] = useState<
    Omit<SettingConfirmationModalProps, "onClose"> &
      Partial<SettingConfirmationModalProps>
  >({
    isOpen: false,
    title: "",
    content: "",
    onDone: () => {},
  });

  const [userRoleToUserMapper, setUserRoleToUserMapper] = useState<{
    [roleId: number]: User;
  }>({});

  const [userToUserRoleMapper, setUserToUserRoleMapper] = useState<{
    [userId: number]: UserRole;
  }>({});

  const [jobSiteOptions, setJobSiteOptions] = useState<SelectOption<number>[]>(
    [],
  );

  const [mapMarkerPosition, setMapMarkerPosition] = useState<
    [number, number] | null
  >(null);
  const [isAddressSearching, setIsAddressSearching] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);

  const [cropImageUrl, setCropImageUrl] = useState<string>("");
  const [isCropImageLoaded, setIsCropImageLoaded] = useState(false);
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [isSubFrame, setIsSubFrame] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useParams();
  const { setSnackbarProps } = useSnackbar();
  const { isSiteAccessable, isAdmin } = useAccess();

  useGalleryTracker(initGallery, "settings", true);

  const cacheManualFocusDistanceRef = useRef(0);
  const cropImageRef = useRef<HTMLImageElement | null>(null);

  const isEdit = !!params.id;

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState,
  } = useForm({});

  const handleAssignedDeviceChanged = async (assignedDeviceId: string) => {
    const firebaseController = getFirebaseController();

    const deviceCache = _deviceCaches[assignedDeviceId];
    const deviceSettingsCache = _deviceSettingsCaches[assignedDeviceId];
    const deviceDetailsCache = _deviceDetailsCaches[assignedDeviceId];

    const device =
      deviceCache ||
      (await firebaseController.Device.getDevice(assignedDeviceId));

    const deviceSettings =
      deviceSettingsCache ||
      (await firebaseController.Device.getDeviceSettings(assignedDeviceId));

    const deviceDetails =
      deviceDetailsCache ||
      (await firebaseController.Device.getDeviceDetails(assignedDeviceId));

    if (device && deviceSettings && deviceDetails) {
      _deviceCaches[assignedDeviceId] = device;
      _deviceSettingsCaches[assignedDeviceId] = deviceSettings;
      _deviceDetailsCaches[assignedDeviceId] = deviceDetails;

      Object.keys(device).forEach((key) => {
        setValue(`device.${key}`, device[key], {
          shouldDirty: false,
        });
      });

      Object.keys(deviceSettings).forEach((key) => {
        setValue(`deviceSettings.${key}`, deviceSettings[key], {
          shouldDirty: false,
        });
      });

      Object.keys(deviceDetails).forEach((key) => {
        setValue(`deviceDetails.${key}`, deviceDetails[key], {
          shouldDirty: false,
        });
      });

      setIsForcedFocus(deviceSettings.triggerTwoStep);
      setIsShowDeviceSettings(true);
    } else {
      setIsShowDeviceSettings(false);
    }

    setDeviceSettingsRenderTrigger(!deviceSettingsRenderTrigger);
  };

  const getAdressAutoCompleteDebounce = useMemo(
    () =>
      _.debounce((addressInput: string) => {
        if (!addressInput) {
          setAddressOptions([]);
          return;
        }

        getAdressAutoComplete(addressInput).then((data) => {
          if (data.results) {
            setAddressOptions(
              data.results.map((result) => {
                return {
                  label: result.formatted,
                  value: result.formatted,
                  lat: result.lat,
                  lon: result.lon,
                };
              }),
            );
          }
        });
      }, 400),
    [],
  );

  const handleAddressSearch = async (value?: string) => {
    setIsAddressSearching(true);

    const addressInput = value || getValues("gallery.address");

    await getGeocode(addressInput)
      .then((data) => {
        setIsAddressSearching(false);

        const { lat, lon } = data.results[0] || {};

        if (!_.isUndefined(lat) && !_.isUndefined(lon)) {
          setMapMarkerPosition([lat, lon]);

          setValue("gallery.pinnedLatitude", lat.toString(), {
            shouldDirty: true,
            shouldValidate: true,
          });

          setValue("gallery.pinnedLongitude", lon.toString(), {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      })
      .catch(() => {
        setIsAddressSearching(false);
      });
  };

  const initJobSiteOptions = (clientId: number) => {
    const client = clientList.find((c) => c.id === clientId);
    const options: SelectOption<number>[] = [];

    if (client) {
      setCurrentClient(client);

      jobSiteList.forEach((jobSite) => {
        if (jobSite.associatedClients.includes(client.id)) {
          options.push({ label: jobSite.name, value: jobSite.id as number });
        }
      });

      setJobSiteOptions(options);
    } else {
      setJobSiteOptions([]);
    }

    return options;
  };

  const setMarkerPositionDebounce = useMemo(
    () =>
      _.debounce(([lat, lon]) => {
        setMapMarkerPosition([Number(lat), Number(lon)]);
      }, 500),
    [],
  );

  const getHostDirecotryAndPrefix = (
    galleryImageHost,
    externalHostDirectory,
  ) => {
    const prefixOptions = IMAGE_HOST_DIRECTORY_OPTIONS_MAPPER[galleryImageHost];

    let currentPrefix = "";
    let parsedHostDirectory = externalHostDirectory;

    for (const prefix of prefixOptions) {
      if (externalHostDirectory.startsWith(prefix)) {
        parsedHostDirectory = externalHostDirectory.slice(prefix.length);
        currentPrefix = prefix;

        break;
      }
    }

    return {
      prefix: currentPrefix,
      hostDirectory: parsedHostDirectory,
    };
  };

  const handleAutoHostDirectory = (formValue) => {
    if (formValue.gallery.galleryImageHost !== "0") {
      return;
    }

    if (isSubFrame) {
      return;
    }

    const [galleryName, siteId, clientId] = getValues([
      "gallery.galleryName",
      "gallery.jobSite",
      "_temp.client",
    ]);

    const site = jobSitesLookup[siteId];

    const client = clientsLookup[clientId];

    const values = [client?.name, site?.name, galleryName].filter((v) => v);

    setValue("gallery.externalHostDirectory", `${values.join("/")}`, {
      shouldDirty: true,
    });
  };

  const jobSitesLookup = useMemo(() => {
    return _.keyBy(jobSiteList, "id");
  }, [jobSiteList]);

  const clientsLookup = useMemo(() => {
    return _.keyBy(clientList, "id");
  }, [clientList]);

  const [userRoleOptions, setUserRoleOptions] = useState<
    SelectOption<number>[]
  >([]);

  const getUsersList = async (
    jobSiteId: number,
  ): Promise<[User[], { [userId: number]: UserRole }]> => {
    if (!jobSiteId) {
      return [[], {}];
    }

    const jobSite = jobSitesLookup[jobSiteId];

    const firebaseController = getFirebaseController();

    const userRoles = await firebaseController.User.getUserRoles({
      accessLevels: ["0", "1", "2", "4"],
      clientIds: jobSite.associatedClients as number[],
    });

    const users = await firebaseController.User.getUsers(
      userRoles.map((role) => role.associatedUser as number),
    );

    const usersLookup = _.keyBy(users, "id");

    const usersIds: number[] = [];
    const userRoleIds: number[] = [];
    const userToUserRoleMapper = {};
    const userRoleToUserMapper = {};

    const userRoleOptions: SelectOption<number>[] = [];

    userRoles.forEach((role) => {
      const userId = role.associatedUser as number;

      usersIds.push(userId);
      userToUserRoleMapper[userId] = role;
      userRoleToUserMapper[role.id as number] = usersLookup[userId];

      if (role.accessableJobSites.includes(jobSiteId)) {
        userRoleIds.push(role.id as number);
      }

      if (jobSite.associatedClients.includes(role.associatedClient)) {
        userRoleOptions.push({
          label: `${usersLookup[role.associatedUser as number].username}  (${
            ROLE_ACCESS_LEVEL_MAPPER[role.accessLevel]
          })`,
          value: role.id as number,
        });
      }
    });

    const sortedUsers = orderByIgnoreCase(users, "username");

    setUserList(sortedUsers);
    setUserRoleOptions(userRoleOptions);
    setUserToUserRoleMapper(userToUserRoleMapper);
    setUserRoleToUserMapper(userRoleToUserMapper);

    return [sortedUsers, userToUserRoleMapper];
  };

  const getGalleryList = useCallback(
    async (jobSiteId) => {
      const firebaseController = getFirebaseController();

      const galleries = await firebaseController.Gallery.getGalleries({
        jobSiteIds: [jobSiteId as number],
      });

      const filteredGalleries = galleries.filter((gallery) => {
        if (params.id) {
          return gallery.id !== Number(params.id);
        } else {
          return true;
        }
      });

      setGalleryList(orderByIgnoreCase(filteredGalleries, "galleryName"));
    },
    [params],
  );

  const getParentGallery = async (parentGalleryId) => {
    const gallery = await getFirebaseController().Gallery.getGallery(
      parentGalleryId,
    );

    if (gallery) {
      setParentGallery(gallery);

      return gallery;
    }
  };

  const getSubFramesGalleries = async (galleryId) => {
    const galleries = await getFirebaseController().Gallery.getGalleries({
      parentId: galleryId,
      isWithSubFrame: true,
    });

    setSubFramesGalleries(galleries);
  };

  const initGalleryEdit = async (
    galleryId?: number,
    isNewGallery?: boolean,
  ) => {
    try {
      const firebaseController = getFirebaseController();

      const gallery = await firebaseController.Gallery.getGallery(
        galleryId || params.id,
      );

      if (!gallery || !isSiteAccessable(gallery.jobSite)) {
        throw new Error("Gallery not accessable.");
      }

      // auto change user client based on gallery selected

      const galleryJobSite = jobSitesLookup[gallery.jobSite as number];

      const client = clientList.find((client) => {
        return galleryJobSite.associatedClients[0] === client.id;
      });

      if (!client) {
        throw new Error("Client not found.");
      }

      if (!galleryJobSite) {
        throw new Error("Job site not found.");
      }

      let accessibleUsers: number[] = [];

      await getUsersList(galleryJobSite.id as number).then(
        ([users, roleMapper]) => {
          accessibleUsers = handleAccessibleUsers(
            users,
            roleMapper,
            galleryJobSite.id as number,
          );
        },
      );

      getGalleryList(galleryJobSite.id as number);

      initJobSiteOptions(client.id as number);

      const gallerySettings =
        await firebaseController.Gallery.getGallerySettings(gallery.id);

      let deviceSettings;
      let deviceDetails;
      let device;

      if (gallery.assignedDevice && gallery.galleryImageHost !== "2") {
        device = await firebaseController.Device.getDevice(
          gallery.assignedDevice,
        );

        deviceSettings = await firebaseController.Device.getDeviceSettings(
          gallery.assignedDevice,
        );

        deviceDetails = await firebaseController.Device.getDeviceDetails(
          gallery.assignedDevice,
        );

        setIsShowDeviceSettings(true);
      }

      const isForcedFocus = deviceSettings?.manualFocusDistance !== -1;

      setIsForcedFocus(isForcedFocus);

      const galleryIntervalSettings =
        await firebaseController.Gallery.getGalleryIntervals(gallery.id);

      const galleryName = isNewGallery ? "" : gallery.galleryName;
      const id = isNewGallery ? null : gallery.id;

      setInitGallery({ ...gallery, galleryName, id });
      setGalleryName(galleryName);
      setGalleryImageHost(gallery.galleryImageHost);

      const { prefix, hostDirectory } = getHostDirecotryAndPrefix(
        gallery.galleryImageHost,
        gallery.externalHostDirectory,
      );

      const formGallery = {
        ...gallery,

        externalHostDirectory: hostDirectory,
        address: gallery.address || "",
        galleryName,
        id,
      };

      const [lat, lon] = [gallery.pinnedLatitude, gallery.pinnedLongitude];

      if (lat && lon) {
        const parsedLat = Number(lat);
        const parsedLon = Number(lon);

        // @ts-expect-error pinnedLatitude should be string field but we make it number field on input
        formGallery.pinnedLatitude = parsedLat;

        // @ts-expect-error
        formGallery.pinnedLongitude = parsedLon;

        setMapMarkerPosition([parsedLat, parsedLon]);
      } else {
        setMapMarkerPosition(null);
      }

      reset(
        {
          gallery: {
            ...formGallery,
            galleryName,
          },
          gallerySettings,
          galleryIntervalSettings,
          device,
          deviceSettings,
          deviceDetails,
          _temp: {
            client: client.id,
            isForcedFocus,
            host_directory_prefix: prefix,
            accessibleUsers,
          },
          _init: {
            // this will not changed after init
            accessibleUsers,
          },
        },
        { keepDefaultValues: isNewGallery },
      );

      if (!isNewGallery) {
        if (gallery.isSubFrame) {
          setIsSubFrame(true);

          await initFrame(gallery.id);
          await getParentGallery(gallery.parentId);
        } else {
          await getSubFramesGalleries(gallery.id);
        }
      } else {
        setParentGallery(gallery);
      }
    } catch (err) {
      console.error(err);

      navigate("../");
    }
  };

  const initGalleryNew = () => {
    const gallery = {
      ...getDefaultGallery(),
    };
    const gallerySettings = getDefaultGalleryDeviceSettings();
    const galleryIntervalSettings = getDefaultDeviceIntervalSettings();

    const formData = {
      gallery,
      gallerySettings,
      galleryIntervalSettings,
      _temp: {
        isForcedFocus: false,
        client: undefined,
        host_directory_prefix: "/",
      },
      _init: {},
    };

    const jobSiteIdIdParam = searchParams.get("jobSiteId");

    if (jobSiteIdIdParam) {
      const parsedJobSiteId = Number(jobSiteIdIdParam);

      const jobSite = jobSiteList.find((jobSite) => {
        return jobSite.id === parsedJobSiteId;
      });

      if (jobSite) {
        gallery.jobSite = jobSite.id;
        formData.gallery.jobSite = jobSite.id;

        const clientId = jobSite.associatedClients[0];

        if (clientId) {
          // @ts-expect-error
          formData._temp.client = clientId;
          initJobSiteOptions(clientId);
        }
      } else {
        searchParams.delete("jobSiteId");
        setSearchParams(searchParams);
      }
    }

    setInitGallery(gallery);
    reset(formData);

    setIsLoading(false);
  };

  const getDeviceList = async () => {
    await getFirebaseController()
      .Device.getDevices()
      .then((devices) => {
        setDeviceList(devices.filter((d) => d.id));
      });
  };

  const handleUserAccessChanged = async (
    currentRestrictedIds,
    newRestrictedIds,
    gallery: GalleryV2,
  ) => {
    if (gallery.id) {
      const [allowedUserIds, removedUserIds] = getDifferences<number>(
        currentRestrictedIds,
        newRestrictedIds,
      );

      const promises: Promise<any>[] = [];

      if (removedUserIds.length > 0) {
        promises.push(
          getFirebaseController().User.revokeUserRoleGalleryAccess(
            removedUserIds,
            gallery.id,
          ),
        );
      }

      if (allowedUserIds.length > 0) {
        promises.push(
          getFirebaseController().User.grandUserRoleGalleryAccess(
            allowedUserIds,
            gallery.id,
            gallery.jobSite as number,
          ),
        );
      }

      return await Promise.all(promises);
    }
  };

  const handleSave = async (rawData) => {
    // setIsLoading(true);

    const { _temp: _rawTemp, _init, ...data } = rawData;

    if (
      !initGallery ||
      !galleryName ||
      !currentClient ||
      !data.gallery.jobSite
    ) {
      setSnackbarProps({
        open: true,
        content: `Gallery name, client and job site cannot be empty.`,
        severity: "error",
      });

      setIsLoading(false);
      return;
    }

    const firebaseController = getFirebaseController();

    if (isEdit) {
      const handleGalleryUpdated = async (isPushEvent: boolean = true) => {
        setSnackbarProps({
          open: true,
          content: `Gallery updated successfully!`,
        });

        await initGalleryEdit()
          .then(async () => {
            if (isPushEvent) {
              await handleEditGalleryAdminEvents(
                initGallery,
                gallery,
                initEditedData,
                galleryName,
              );
            }
          })
          .catch((err) => {
            console.error(err);

            navigate(0);
          });

        setIsLoading(false);
      };

      const { _temp: _tempState, ...dirtyFieldsState } = formState.dirtyFields;

      const editedData = getDirtyFields(dirtyFieldsState, data);

      const isHostPrefixChanged = _.has(
        formState.dirtyFields,
        "_temp.host_directory_prefix",
      );

      const isUserChanged = _.has(
        formState.dirtyFields,
        "_temp.accessibleUsers",
      );

      if (isUserChanged) {
        await handleUserAccessChanged(
          _init.accessibleUsers,
          _rawTemp.accessibleUsers,
          initGallery,
        );
      }

      if (_.isEmpty(editedData) && !isHostPrefixChanged) {
        setIsLoading(false);

        if (isUserChanged) {
          handleGalleryUpdated(false);
        }

        return;
      }

      const {
        gallery,
        gallerySettings,
        galleryIntervalSettings,
        device,
        deviceSettings,
      } = editedData;

      const initEditedData = _.cloneDeep(editedData);

      if (gallery && "cropPosition" in gallery) {
        gallery.cropPosition = {
          ...initGallery.cropPosition,
          ...gallery.cropPosition,
        };
      }

      if (
        gallery &&
        ("galleryName" in gallery ||
          "jobSite" in gallery ||
          "assignedDevice" in gallery)
      ) {
        // we need both data if either of this fields changed, to update device's frontEnd name

        gallery.galleryName = getValues("gallery.galleryName");
        gallery.jobSite = getValues("gallery.jobSite");
      }

      let galleryDataToUpdate = gallery ? { ...gallery } : undefined;

      if (
        isHostPrefixChanged ||
        (gallery && "externalHostDirectory" in gallery)
      ) {
        const hostDirectory = rawData.gallery.externalHostDirectory
          ? _rawTemp.host_directory_prefix +
            rawData.gallery.externalHostDirectory
          : "";

        galleryDataToUpdate ||= {};
        galleryDataToUpdate.externalHostDirectory = hostDirectory;
      }

      await firebaseController.Gallery.updateGallery(
        data.gallery.id,
        currentClient,
        {
          gallery: galleryDataToUpdate,
          gallerySettings,
          galleryIntervalSettings,
          currentAssignedDeviceId: initGallery.assignedDevice,
          device,
          deviceSettings,
        },
      )
        .then(async () => {
          await handleGalleryUpdated();
        })
        .catch((err) => {
          console.error(err);

          setSnackbarProps({
            open: true,
            content: `Gallery update failed!`,
            severity: "error",
          });
        });

      setIsLoading(false);

      delete _jobSiteCaches[data.gallery.id as number];
    } else {
      const { gallery, gallerySettings, galleryIntervalSettings } = data;
      const {
        _init: _initState,
        _temp: _tempState,
        ...dirtyFieldsState
      } = formState.dirtyFields;

      const editedData = getDirtyFields(dirtyFieldsState, data);

      // these settings is fetch from assigned device, which user might change it when creating gallery
      const { device, deviceSettings } = editedData;

      const hostDirectory = editedData.gallery.externalHostDirectory
        ? _rawTemp.host_directory_prefix +
          editedData.gallery.externalHostDirectory
        : "";

      const isUserChanged = _.has(
        formState.dirtyFields,
        "_temp.accessibleUsers",
      );

      const newGalleryId = firebaseController.getNewDocumentId();

      const newGallery = {
        ...gallery,
        id: newGalleryId,
        externalHostDirectory: hostDirectory,
      };

      const initAccessibleUsers = getAccessibleUsers(
        userList,
        userToUserRoleMapper,
        newGalleryId,
        newGallery.jobSite,
      );

      await firebaseController.Gallery.addGallery(
        currentClient,
        newGallery,
        gallerySettings,
        galleryIntervalSettings,
        { device, deviceSettings },
      )
        .then(async () => {
          if (isUserChanged) {
            await handleUserAccessChanged(
              initAccessibleUsers,
              _rawTemp.accessibleUsers,
              newGallery,
            );
          }

          setIsLoading(false);

          setSnackbarProps({
            open: true,
            content: `Gallery created successfully!`,
          });

          const jobSiteIdParam = searchParams.get("jobSiteId");
          const galleryIdParam = searchParams.get("galleryId");

          if (jobSiteIdParam) {
            navigate(`/job-sites/${jobSiteIdParam}`);
          } else if (galleryIdParam) {
            navigate(`/galleries/${galleryIdParam}`);
          } else {
            navigate("../");
          }
        })
        .catch((err) => {
          console.error(err);

          setSnackbarProps({
            open: true,
            content: `Gallery create failed!`,
            severity: "error",
          });
        });
    }
  };

  const handleIntervalSettingsCopy = (day) => {
    const values = getValues(`galleryIntervalSettings.${day}`);

    DAY_NAMES.forEach((day) => {
      setValue(`galleryIntervalSettings.${day}`, values, { shouldDirty: true });
    });

    setIntervalSettingsRenderTrigger((prev) => !prev);
  };

  const getAccessibleUsers = (
    userList: User[],
    userToUserRoleMapper: { [userId: number]: UserRole },
    galleryId: number,
    jobSiteId: number,
  ) => {
    const accessibleUsers = userList.reduce<number[]>((ids, user) => {
      const userRole = userToUserRoleMapper[user.id as number];

      if (userRole) {
        const isJobSiteMatch = userRole.accessableJobSites.includes(jobSiteId);

        if (
          isJobSiteMatch &&
          !userRole.restrictedGalleries?.includes(galleryId)
        ) {
          ids.push(userRole.id as number);
        }
      }

      return ids;
    }, []);

    return accessibleUsers;
  };

  const handleAccessibleUsers = (
    userList: User[],
    userToUserRoleMapper: { [userId: number]: UserRole },
    jobSiteId: number,
  ) => {
    const accessibleUsers = getAccessibleUsers(
      userList,
      userToUserRoleMapper,
      Number(params.id),
      jobSiteId,
    );

    setValue("_temp.accessibleUsers", accessibleUsers);

    return accessibleUsers;
  };

  const deviceOptions = deviceList.map((device) => {
    return { label: device.friendlyName || device.deviceId, value: device.id };
  });

  const clientOptions = useMemo(() => {
    return clientList.map((client) => {
      return { label: client.name, value: client.id };
    });
  }, [clientList]);

  const userOptions = useMemo(() => {
    return userList.map((user) => {
      return {
        label: user.username,
        value: user.id,
      };
    });
  }, [userList]);

  const imageHostOptions = Object.keys(IMAGE_HOST_MAPPER).map((value) => {
    return { value, label: IMAGE_HOST_MAPPER[value] };
  });

  const rotateOptions = useMemo(() => {
    return Object.keys(ROTATE_VALUE_MAPPER).map((value) => {
      return { value: Number(value), label: ROTATE_VALUE_MAPPER[value] };
    });
  }, []);

  const autoWhiteBalanceModeOptions = useMemo(() => {
    return Object.keys(AUTO_WHITE_BALANCE_MODE_NAME_MAPEPR).map((value) => {
      return {
        value: Number(value),
        label: AUTO_WHITE_BALANCE_MODE_NAME_MAPEPR[value],
      };
    });
  }, []);

  const deviceChannelOptions = useMemo(() => {
    return Object.keys(UPDATE_CHANNEL_MAPPER).map((value) => {
      return { value: Number(value), label: UPDATE_CHANNEL_MAPPER[value] };
    });
  }, []);

  const gallerySettingsRow: SettingRow[] = useMemo(
    () => [
      ...conditionalPush(
        {
          settingName: "Main Gallery",
          value: (
            <>
              {parentGallery && (
                <DataChips<GalleryV2>
                  items={[parentGallery]}
                  labelkey="galleryName"
                  idKey="id"
                  itemLink={(item) => `/galleries/${item.id}`}
                  limit={5}
                />
              )}
            </>
          ),
        },
        isSubFrame,
      ),
      {
        settingName: "Name",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("gallery.galleryName", {
              required: true,
              setValueAs: (value) => {
                return _.startCase(value);
              },
            })}
            error={!!_.get(formState.errors, "gallery.galleryName")}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Archived",
        value: (
          <Controller
            name="gallery.archived"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Visible",
        value: (
          <Controller
            key="gallery.visible"
            name="gallery.visible"
            defaultValue={true}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Image Host",
        value: (
          <Controller
            control={control}
            name={"gallery.galleryImageHost"}
            defaultValue={"0"}
            render={({ field }) => {
              return (
                <TextField
                  {...baseSettingTextFieldProps}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value}
                  select
                  disabled={isLoading || isEdit || isSubFrame}
                >
                  {imageHostOptions.map((option) => (
                    <MenuItem dense key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
        ),
      },
      ...conditionalPush(
        {
          settingName: "Use Proxy Api",
          value: (
            <Controller
              key="gallery.useProxyApi"
              name="gallery.useProxyApi"
              defaultValue={false}
              control={control}
              render={(props) => {
                return (
                  <Switch
                    onChange={(e) => props.field.onChange(e.target.checked)}
                    checked={props.field.value}
                    disabled={isLoading}
                    {...baseSettingSwitchProps}
                  />
                );
              }}
            />
          ),
        },
        galleryImageHost === "1",
      ),
      {
        settingName: "External Host Directory",
        value: (
          <Controller
            key={"gallery.externalHostDirectory"}
            control={control}
            name="gallery.externalHostDirectory"
            defaultValue={""}
            render={({ field }) => {
              const { ref, ...restField } = field;

              return (
                <Controller
                  control={control}
                  name="_temp.host_directory_prefix"
                  defaultValue={""}
                  render={({ field: hostField }) => {
                    return (
                      <PrefixTextField
                        {...restField}
                        disabled={
                          galleryImageHost === "0" || isLoading || isSubFrame
                        }
                        inputRef={ref}
                        prefix={hostField.value}
                        prefixOptions={
                          IMAGE_HOST_DIRECTORY_OPTIONS_MAPPER[galleryImageHost]
                        }
                        onPrefixChange={(prefix) => {
                          hostField.onChange(prefix);
                        }}
                      />
                    );
                  }}
                />
              );
            }}
          />
        ),
      },
      ...conditionalPush(
        {
          settingName: "Assigned Device",
          value: (
            <Controller
              control={control}
              name={"gallery.assignedDevice"}
              render={({ field }) => {
                const currentOption =
                  deviceOptions.find((o) => o.value === field.value) || null;

                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {galleryImageHost === "2" ? (
                      <TextField
                        {...baseSettingTextFieldProps}
                        {...register("gallery.assignedDevice", {
                          setValueAs: (value) => {
                            return _.startCase(value);
                          },
                        })}
                        disabled={isLoading}
                      />
                    ) : (
                      <>
                        <Autocomplete
                          {...baseSettingAutocompleteProps}
                          disabled={isLoading || galleryImageHost !== "0"}
                          onChange={(e, data) =>
                            field.onChange(data?.value || null)
                          }
                          value={currentOption}
                          options={deviceOptions}
                          renderInput={(params: any) => (
                            <TextField {...params} />
                          )}
                        />

                        {currentOption && (
                          <SettingLink to={`/devices/${currentOption.value}`} />
                        )}
                      </>
                    )}
                  </Box>
                );
              }}
            />
          ),
        },
        galleryImageHost === "0",
      ),
      {
        settingName: "Client",
        value: (
          <Controller
            control={control}
            name={"_temp.client"}
            rules={{ required: true }}
            render={({ field }) => {
              const currentOption =
                clientOptions.find((o) => o.value === field.value) || null;

              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Autocomplete
                    {...baseSettingAutocompleteProps}
                    disableClearable
                    disabled={isLoading || isEdit}
                    onChange={(e, data) =>
                      field.onChange(data?.value || clientOptions[0].value)
                    }
                    value={currentOption}
                    options={clientOptions}
                    renderInput={(params: any) => (
                      <TextField
                        error={!!_.get(formState.errors, "_temp.client")}
                        {...params}
                      />
                    )}
                  />

                  {currentOption && (
                    <SettingLink to={`/clients/${currentOption.value}`} />
                  )}
                </Box>
              );
            }}
          />
        ),
      },
      {
        settingName: "Job Site",
        value: (
          <Box>
            <Controller
              control={control}
              name={"gallery.jobSite"}
              rules={{ required: true }}
              render={({ field }) => {
                const currentOption =
                  jobSiteOptions.find((o) => o.value === field.value) || null;

                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      {...baseSettingAutocompleteProps}
                      disableClearable
                      disabled={isLoading}
                      onChange={(e, data) => {
                        if (isEdit) {
                          setConfirmationModalParams({
                            isOpen: true,
                            title: "Change gallery job site?",
                            content:
                              "Note that this will affect the users who can access this gallery. Are you sure you want to proceed?",
                            onDone: () => {
                              field.onChange(
                                data?.value || jobSiteOptions[0].value,
                              );
                            },
                          });
                        } else {
                          field.onChange(
                            data?.value || jobSiteOptions[0].value,
                          );
                        }
                      }}
                      value={currentOption}
                      options={jobSiteOptions}
                      renderInput={(params: any) => (
                        <TextField
                          error={!!_.get(formState.errors, "gallery.jobSite")}
                          {...params}
                        />
                      )}
                    />
                    {currentOption && (
                      <SettingLink to={`/job-sites/${currentOption.value}`} />
                    )}
                  </Box>
                );
              }}
            />

            {galleryList.length > 0 && (
              <>
                <Divider sx={{ my: 1.5 }} />

                <Box>
                  <Typography
                    sx={{
                      // fontWeight: "bold",
                      fontSize: "12px !important",
                      textDecoration: "underline",
                      lineHeight: 1,
                      mb: 1,
                    }}
                  >
                    Other Galleries:
                  </Typography>

                  <DataChips
                    items={galleryList}
                    labelkey="galleryName"
                    itemLink={(gallery) => `/galleries/${gallery.id}`}
                  />
                </Box>
              </>
            )}
          </Box>
        ),
      },
      {
        settingName: "Accessible Users",
        value: (
          <Box>
            <Box sx={{ mt: 1 }}>
              <Controller
                control={control}
                name={"_temp.accessibleUsers"}
                defaultValue={[]}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...baseSettingAutocompleteProps}
                      multiple
                      disabled={isLoading}
                      disableCloseOnSelect
                      onChange={(e, data, reason) => {
                        if (isEdit && reason === "clear") {
                          setConfirmationModalParams({
                            isOpen: true,
                            title: `Revoke all users access to this gallery?`,
                            content:
                              "Are you sure you want to revoke all user access to this gallery?",
                            onDone: () => {
                              field.onChange(
                                data.map((d) => d.value as number) || [],
                              );
                            },
                          });
                        } else if (isEdit && reason === "selectOption") {
                          const option = _.last(data);

                          setConfirmationModalParams({
                            isOpen: true,
                            title: `Grant user "${option.label}" access to this gallery?`,
                            content: `Are you sure you want to grant user "${option.label}" access to this gallery?`,
                            onDone: () => {
                              field.onChange(
                                data.map((d) => d.value as number) || [],
                              );
                            },
                          });
                        } else {
                          field.onChange(
                            data.map((d) => d.value as number) || [],
                          );
                        }
                      }}
                      value={field.value.map((v) =>
                        userRoleOptions.find((o) => o.value === v),
                      )}
                      renderTags={(values, getTagProps) => {
                        return values.map((option, index) => {
                          return (
                            <Chip
                              color="secondary"
                              size="small"
                              label={option.label}
                              sx={({ palette }) => ({
                                borderRadius: 0.5,
                                backgroundColor: palette.primary.light,
                                color: `${palette.white.main} !important`,
                              })}
                              {...getTagProps({ index })}
                              onDelete={(e) => {
                                e.stopPropagation();

                                if (isEdit) {
                                  setConfirmationModalParams({
                                    isOpen: true,
                                    title: `Revoke user "${option.label}" access to this gallery?`,
                                    content: `Are you sure you want to revoke user "${option.label}" access to this gallery?`,
                                    onDone: () => {
                                      getTagProps({ index }).onDelete(e);
                                    },
                                  });
                                } else {
                                  getTagProps({ index }).onDelete(e);
                                }
                              }}
                            />
                          );
                        });
                      }}
                      options={userRoleOptions}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  );
                }}
              />

              <Box sx={{ mt: 1 }}>
                <FormControlLabel
                  label={`Select all`}
                  sx={{ margin: "0 !important" }}
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: {
                          xs: 12,
                          sm: 14,
                        },
                      },
                    },
                  }}
                  control={
                    <Controller
                      name={"isAllUsers"}
                      control={control}
                      defaultValue={false}
                      render={({ field: props }) => (
                        <Checkbox
                          {...props}
                          sx={{ padding: 0, mr: 1 }}
                          disabled={isLoading}
                          size="small"
                          checked={props.value}
                          onChange={(e) => {
                            props.onChange(e.target.checked);

                            if (e.target.checked) {
                              const allIds = userRoleOptions.map(
                                (role) => role.value,
                              );

                              setValue("_temp.accessibleUsers", allIds, {
                                shouldDirty: true,
                              });
                            }
                          }}
                        />
                      )}
                    />
                  }
                />
              </Box>
            </Box>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      control,
      deviceOptions,
      galleryImageHost,
      imageHostOptions,
      isLoading,
      clientOptions,
      jobSiteOptions,
      userOptions,
      register,
      setValue,
      currentUserClient,
      isEdit,
      userList,
      galleryList,
      parentGallery,
      userRoleOptions,
    ],
  );

  const frameSettingsRow: SettingRow[] = useMemo(() => {
    const onBlur = (e, field: string, type: "px" | "%") => {
      const value = _.round(Number(e.target.value), 2);

      if (cropImageRef.current) {
        const { width, height } = cropImageRef.current;

        const currentPixelCrop = convertToPixelCrop(crop, width, height);

        const nextCrop = convertToPixelCrop(
          {
            ...(type === "%" ? crop : currentPixelCrop),
            [field]: value,
          },
          width,
          height,
        );

        switch (field) {
          case "x": {
            nextCrop.x = clamp(nextCrop.x, 0, width - nextCrop.width);
            break;
          }
          case "y": {
            nextCrop.y = clamp(nextCrop.y, 0, height - nextCrop.height);
            break;
          }
          case "width": {
            nextCrop.width = clamp(nextCrop.width, 0, width - nextCrop.x);
            break;
          }

          case "height": {
            nextCrop.height = clamp(nextCrop.height, 0, height - nextCrop.y);
            break;
          }
        }

        const percentCrop = convertToPercentCrop(nextCrop, width, height);

        const roundedPixelCrop = roundCropPosition(nextCrop);

        const roundedCrop = {
          ...percentCrop,
          ...roundCropPosition(percentCrop),
        };

        setValue(`gallery.cropPosition.${field}`, roundedCrop[field], {
          shouldDirty: true,
        });

        setValue(`temp._pixelCropPosition.${field}`, roundedPixelCrop[field], {
          shouldDirty: true,
        });

        setCrop(roundedCrop);
      }
    };

    return [
      {
        settingName: "X Position",
        value: (
          <CropInput
            field="x"
            register={register}
            onBlur={onBlur}
            disabled={isLoading || !isCropImageLoaded}
          />
        ),
      },
      {
        settingName: "Y Position",
        value: (
          <CropInput
            field="y"
            register={register}
            onBlur={onBlur}
            disabled={isLoading || !isCropImageLoaded}
          />
        ),
      },
      {
        settingName: "Width",
        value: (
          <CropInput
            field="width"
            register={register}
            onBlur={onBlur}
            disabled={isLoading || !isCropImageLoaded}
          />
        ),
      },
      {
        settingName: "Height",
        value: (
          <CropInput
            field="height"
            register={register}
            onBlur={onBlur}
            disabled={isLoading || !isCropImageLoaded}
          />
        ),
      },
      {
        settingName: "Crop",
        value: (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  width: 500,
                }}
              >
                {cropImageUrl ? (
                  <ReactCrop
                    disabled={isLoading}
                    keepSelection
                    crop={crop}
                    onChange={(c, pc) => {
                      if (cropImageRef.current) {
                        const newPosition = roundCropPosition(pc);

                        const newPixelPosition = roundCropPosition(
                          convertToPixelCrop(
                            pc,
                            cropImageRef.current.width,
                            cropImageRef.current.height,
                          ),
                        );

                        setCrop({ unit: pc.unit, ...newPosition });

                        setValue("gallery.cropPosition", newPosition, {
                          shouldDirty: true,
                        });

                        setValue("temp._pixelCropPosition", newPixelPosition, {
                          shouldDirty: true,
                        });
                      }
                    }}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      crossOrigin="anonymous"
                      id="img"
                      sx={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                        userSelect: "none",
                        pointerEvents: "none",
                      }}
                      component={"img"}
                      ref={cropImageRef}
                      src={cropImageUrl}
                    />
                  </ReactCrop>
                ) : (
                  <Box
                    sx={{
                      height: 300,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LDSRingLoader />
                  </Box>
                )}
              </Box>

              <Controller
                control={control}
                name={"temp._cropImageDimension"}
                defaultValue={""}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      {field.value && (
                        <Box
                          sx={{
                            mt: 0,
                            textAlign: "center",
                            display: "block",
                          }}
                        >
                          {field.value.width} x {field.value.height}
                        </Box>
                      )}
                    </>
                  );
                }}
              />
            </Box>
          </Box>
        ),
      },
    ];
  }, [
    control,
    isLoading,
    register,
    setValue,
    cropImageUrl,
    isCropImageLoaded,
    crop,
  ]);

  const locationSettingsRow: SettingRow[] = useMemo(
    () => [
      {
        settingName: "Address",

        value: (
          <Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Controller
                control={control}
                name={"gallery.address"}
                defaultValue={""}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      {...baseSettingAutocompleteProps}
                      disabled={isLoading}
                      disableClearable
                      filterOptions={(x) => x}
                      freeSolo
                      onInputChange={(e, newInputValue) => {
                        field.onChange(newInputValue);
                        getAdressAutoCompleteDebounce(newInputValue);
                      }}
                      onChange={(e, data) => {
                        field.onChange(data.value);

                        setMapMarkerPosition([data.lat, data.lon]);

                        setValue("gallery.pinnedLatitude", data.lat, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                        setValue("gallery.pinnedLongitude", data.lon, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      value={field.value}
                      options={addressOptions}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  );
                }}
              />

              <Button
                size="small"
                disableElevation
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontSize: 12,
                  p: 0,
                  color: ({ palette }) =>
                    `${palette.onPrimary.main} !important`,
                }}
                onClick={() => handleAddressSearch()}
                disabled={isLoading || isAddressSearching}
              >
                Search
              </Button>
            </Box>

            <Box
              sx={{
                marginTop: 2,
                height: 300,
              }}
            >
              <Map
                center={[-37.5873527, 145.1229405]}
                markerPosition={mapMarkerPosition}
                mapOnClick={(e, map) => {
                  const { lat, lng } = e.latlng;

                  setMapMarkerPosition([lat, lng]);

                  map.setView([lat, lng], map.getZoom());

                  setValue("gallery.pinnedLatitude", lat.toString(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue("gallery.pinnedLongitude", lng.toString(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Box>
          </Box>
        ),
      },
      {
        settingName: "Latitude",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("gallery.pinnedLatitude", {
              min: -90,
              max: 90,
            })}
            type="number"
            inputProps={{
              ...baseSettingTextFieldProps.inputProps,
              min: -90,
              max: 90,
              step: 0.1,
            }}
            error={!!_.get(formState.errors, "gallery.pinnedLatitude")}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Longitude",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("gallery.pinnedLongitude", {
              min: -180,
              max: 180,
            })}
            type="number"
            inputProps={{
              ...baseSettingTextFieldProps.inputProps,
              min: -180,
              max: 180,
              step: 0.1,
            }}
            error={!!_.get(formState.errors, "gallery.pinnedLongitude")}
            disabled={isLoading}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      control,
      formState,
      isLoading,
      register,
      mapMarkerPosition,
      isAddressSearching,
      addressOptions,
    ],
  );

  const deviceSettingRows: SettingRow[] = useMemo(
    () => [
      {
        id: "gallerySettings.exposureTime",
        settingName: "Exposure Time",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("gallerySettings.exposureTime", {
              valueAsNumber: true,
            })}
            type="number"
            sx={{ width: 100 }}
            disabled={isLoading}
          />
        ),
      },
      {
        id: "gallerySettings.autoExposure",
        settingName: "Auto Exposure",
        value: (
          <Controller
            name="gallerySettings.autoExposure"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        id: "gallerySettings.autoFocus",
        settingName: "Auto Focus",
        value: (
          <Controller
            name="gallerySettings.autoFocus"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      ...(isShowDeviceSettings
        ? [
            {
              id: "deviceSettings.triggerTwoStep",
              settingName: "Trigger Two Step",
              value: (
                <Controller
                  name="deviceSettings.triggerTwoStep"
                  defaultValue={false}
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                      disabled={isLoading}
                      {...baseSettingSwitchProps}
                    />
                  )}
                />
              ),
            },
            {
              id: "_temp.isForcedFocus",
              settingName: "Manually Forced Focus",
              value: (
                <Box
                  sx={{
                    display: "flex",
                    // flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Controller
                    name="_temp.isForcedFocus"
                    defaultValue={false}
                    control={control}
                    render={(props) => (
                      <Switch
                        onChange={(e) => props.field.onChange(e.target.checked)}
                        checked={props.field.value}
                        disabled={isLoading}
                        {...baseSettingSwitchProps}
                      />
                    )}
                  />

                  {isForcedFocus && (
                    <TextField
                      {...baseSettingTextFieldProps}
                      {...register("deviceSettings.manualFocusDistance", {
                        valueAsNumber: true,
                      })}
                      type="number"
                      sx={{ width: 100 }}
                      disabled={isLoading}
                    />
                  )}
                </Box>
              ),
            },
            {
              id: "deviceDetails.twoStepFocusValue",
              settingName: "Two Step Focus Value",
              value: (
                <Controller
                  name="deviceDetails.twoStepFocusValue"
                  control={control}
                  render={(props) => (
                    <Typography sx={{ fontSize: 14 }}>
                      {props.field.value}
                    </Typography>
                  )}
                />
              ),
            },
          ]
        : []),

      {
        id: "gallerySettings.autoWhiteBalance",
        settingName: "Auto White Balance",
        value: (
          <Controller
            name="gallerySettings.autoWhiteBalance"
            defaultValue={true}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        id: "gallerySettings.autoWhiteBalanceMode",
        settingName: "Auto White Balance Mode",
        value: (
          <Controller
            control={control}
            name="gallerySettings.autoWhiteBalanceMode"
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value || rotateOptions[0].value}
                select
                disabled={isLoading}
                SelectProps={{
                  MenuProps: {
                    sx: { height: "300px" },
                  },
                }}
                sx={{ width: 100 }}
              >
                {autoWhiteBalanceModeOptions.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
      ...(isShowDeviceSettings
        ? [
            {
              id: "deviceSettings.setupMode",
              settingName: "Setup Mode",
              value: (
                <Controller
                  name="deviceSettings.setupMode"
                  defaultValue={false}
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                      disabled={isLoading}
                      {...baseSettingSwitchProps}
                    />
                  )}
                />
              ),
            },
          ]
        : []),
      {
        id: "gallerySettings.rotate",
        settingName: "Rotate",
        value: (
          <Controller
            control={control}
            name="gallerySettings.rotate"
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value || rotateOptions[0].value}
                select
                disabled={isLoading}
                SelectProps={{
                  MenuProps: {
                    sx: { height: "300px" },
                  },
                }}
                sx={{ width: 100 }}
              >
                {rotateOptions.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
      {
        id: "gallerySettings.gain",
        settingName: "Gain",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("gallerySettings.gain", { valueAsNumber: true })}
            type="number"
            sx={{ width: 100 }}
            disabled={isLoading}
          />
        ),
      },
      {
        id: "gallerySettings.brightness",
        settingName: "Brightness",
        value: (
          <Controller
            control={control}
            name="gallerySettings.brightness"
            render={({ field }) => (
              <SliderInput
                min={BRIGHTNESS_RANGE[0]}
                max={BRIGHTNESS_RANGE[1]}
                step={0.01}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      {
        id: "gallerySettings.contrast",
        settingName: "Contrast",
        value: (
          <Controller
            control={control}
            name="gallerySettings.contrast"
            render={({ field }) => (
              <SliderInput
                min={CONTRAST_RANGE[0]}
                max={CONTRAST_RANGE[1]}
                step={0.1}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      {
        id: "gallerySettings.saturation",
        settingName: "Saturation",
        value: (
          <Controller
            control={control}
            name="gallerySettings.saturation"
            render={({ field }) => (
              <SliderInput
                min={SATURATION_RANGE[0]}
                max={SATURATION_RANGE[1]}
                step={0.1}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      {
        id: "gallerySettings.sharpness",
        settingName: "Sharpness",
        value: (
          <Controller
            control={control}
            name="gallerySettings.sharpness"
            render={({ field }) => (
              <SliderInput
                min={SHARPNESS_RANGE[0]}
                max={SHARPNESS_RANGE[1]}
                step={0.1}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      ...(isShowDeviceSettings
        ? [
            {
              id: "deviceSettings.maxSleepTime",
              settingName: "Max Sleep Time",
              value: (
                <TextField
                  {...baseSettingTextFieldProps}
                  {...register("deviceSettings.maxSleepTime", {
                    valueAsNumber: true,
                  })}
                  disabled={isLoading}
                  type="number"
                  sx={{ width: 100 }}
                />
              ),
            },
            {
              id: "device.deg90Camera",
              settingName: "90 Degree Camera",
              value: (
                <Controller
                  name="device.deg90Camera"
                  defaultValue={true}
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                      disabled={isLoading}
                      {...baseSettingSwitchProps}
                    />
                  )}
                />
              ),
            },
            {
              id: "deviceSettings.channel",
              settingName: "Update Channel",
              value: (
                <Controller
                  control={control}
                  name={"deviceSettings.channel"}
                  defaultValue={"0"}
                  render={({ field }) => (
                    <TextField
                      {...baseSettingTextFieldProps}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      select
                      disabled={isLoading}
                    >
                      {deviceChannelOptions.map((option) => (
                        <MenuItem dense key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              ),
            },
            {
              id: "deviceSettings.performUpdate",
              settingName: "Perform Update",
              value: (
                <Controller
                  name="deviceSettings.performUpdate"
                  defaultValue={false}
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                      disabled={isLoading}
                      {...baseSettingSwitchProps}
                    />
                  )}
                />
              ),
            },
            {
              id: "deviceSettings.updateCheckFrom",
              settingName: "Update Check Start Time",
              value: (
                <Controller
                  control={control}
                  name="deviceSettings.updateCheckFrom"
                  render={({ field }) => (
                    <TextField
                      {...baseSettingTextFieldProps}
                      sx={{
                        width: 100,
                      }}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value || UPDATE_TIME_OPTIONS[0].value}
                      select
                      disabled={isLoading}
                      SelectProps={{
                        MenuProps: {
                          sx: { height: "300px" },
                        },
                      }}
                    >
                      {UPDATE_TIME_OPTIONS.map((option) => (
                        <MenuItem dense key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              ),
            },
            {
              id: "deviceSettings.updateCheckTo",
              settingName: "Update Check Finish Time",
              value: (
                <Controller
                  control={control}
                  name="deviceSettings.updateCheckTo"
                  render={({ field }) => (
                    <TextField
                      {...baseSettingTextFieldProps}
                      sx={{
                        width: 100,
                      }}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value || UPDATE_TIME_OPTIONS[0].value}
                      select
                      disabled={isLoading}
                      SelectProps={{
                        MenuProps: {
                          sx: { height: "300px" },
                        },
                      }}
                    >
                      {UPDATE_TIME_OPTIONS.map((option) => (
                        <MenuItem dense key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              ),
            },
          ]
        : []),
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      control,
      isLoading,
      register,
      rotateOptions,
      autoWhiteBalanceModeOptions,
      deviceChannelOptions,
      isForcedFocus,
      isShowDeviceSettings,
      deviceSettingsRenderTrigger,
    ],
  );

  const subFramesRow: SubFramesRows[] = useMemo(() => {
    return subFramesGalleries.map((gallery) => {
      const userIds = getAccessibleUsers(
        userList,
        userToUserRoleMapper,
        gallery.id as number,
        gallery.jobSite as number,
      );

      return {
        name: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.5,
            }}
          >
            {gallery.galleryName}
          </Box>
        ),
        users: (
          <Box
            sx={{
              width: "100%",
            }}
          >
            <DataChips<number>
              isItemActive={() => true}
              items={userIds}
              labelFormat={(id) => {
                const user = userRoleToUserMapper[id];
                const role = userToUserRoleMapper[user.id as number];

                return `${user.username} (${
                  ROLE_ACCESS_LEVEL_MAPPER[role.accessLevel]
                })`;
              }}
              itemLink={(id) => {
                const user = userRoleToUserMapper[id];

                return `/users/${user.id}`;
              }}
              limit={5}
            />
          </Box>
        ),
        frame: (
          <Box
            sx={{
              width: "100%",
              minWidth: 200,
              background: ({ palette }) => palette.secondary.main,
            }}
          >
            <SubFrameGalleryThumbnail gallery={gallery} />
          </Box>
        ),
        actions: (
          <>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();

                navigate(`../${gallery.id}/image-viewer`);
              }}
            >
              <OpenInNew
                fontSize="small"
                sx={{
                  xs: 14,
                  sm: 20,
                }}
              />
            </IconButton>

            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();

                navigate(`../${gallery.id}`);
              }}
              sx={{ ml: 0.5 }}
            >
              <Settings
                fontSize="small"
                sx={{
                  xs: 14,
                  sm: 20,
                }}
              />
            </IconButton>
          </>
        ),
      };
    });
  }, [subFramesGalleries, userToUserRoleMapper, userList]);

  const intervalSettingsRows: IntervalSettingsRow[] = useMemo(() => {
    return DAY_NAMES.flatMap((day) => {
      const isActive = getValues(`galleryIntervalSettings.${day}.active`);
      const isBurst = getValues(`galleryIntervalSettings.${day}.burst`);

      return [
        {
          id: day,
          active: (
            <Controller
              name={`galleryIntervalSettings.${day}.active`}
              defaultValue={false}
              control={control}
              render={({ field }) => (
                <Switch
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                  disabled={isLoading}
                  {...baseSettingSwitchProps}
                />
              )}
            />
          ),
          day: (
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
              sx={{
                color: ({ palette }) =>
                  isActive ? "" : alpha(palette.onSurface.main, 0.4),
                columnGap: 2,
              }}
            >
              <Grid item>{day}</Grid>
              <Grid item xs={12} md="auto">
                <FormControlLabel
                  label="Burst"
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: {
                          xs: 12,
                          sm: 14,
                        },
                      },
                    },
                  }}
                  sx={{
                    margin: 0,
                    mr: 1,
                  }}
                  control={
                    <Controller
                      name={`galleryIntervalSettings.${day}.burst`}
                      control={control}
                      defaultValue={false}
                      render={({ field: props }) => (
                        <Checkbox
                          {...props}
                          sx={{
                            padding: 0,
                            mr: {
                              xs: 0.5,
                              sm: 1,
                            },
                          }}
                          disabled={isLoading || !isActive}
                          size="small"
                          checked={props.value}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                />
              </Grid>
            </Grid>
          ),
          startTime: (
            <Controller
              control={control}
              name={`galleryIntervalSettings.${day}.timeFrom`}
              render={({ field }) => (
                <TextField
                  {...baseSettingTextFieldProps}
                  sx={{
                    ...baseSettingTextFieldProps.sx,
                    width: "min-content",
                  }}
                  fullWidth
                  onChange={(e) =>
                    handleIntervalTimeChange(e.target.value, field)
                  }
                  value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                  select
                  disabled={isLoading || !isActive}
                  SelectProps={{
                    MenuProps: {
                      sx: { height: "300px" },
                    },
                  }}
                >
                  {INTERVAL_TIME_OPTIONS.map((option) => (
                    <MenuItem dense key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ),
          finishTime: (
            <Controller
              control={control}
              name={`galleryIntervalSettings.${day}.timeTo`}
              render={({ field }) => (
                <TextField
                  {...baseSettingTextFieldProps}
                  sx={{
                    ...baseSettingTextFieldProps.sx,
                    width: "min-content",
                  }}
                  onChange={(e) =>
                    handleIntervalTimeChange(e.target.value, field)
                  }
                  value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                  select
                  disabled={isLoading || !isActive}
                  SelectProps={{
                    MenuProps: {
                      sx: { height: "300px" },
                    },
                  }}
                >
                  {INTERVAL_TIME_OPTIONS.map((option) => (
                    <MenuItem dense key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ),
          interval: (
            <Controller
              control={control}
              name={`galleryIntervalSettings.${day}.timeBetweenShots`}
              render={({ field }) => (
                <TextField
                  {...baseSettingTextFieldProps}
                  sx={{
                    ...baseSettingTextFieldProps.sx,
                    width: "min-content",
                  }}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value || INTERVAL_OPTIONS[1].value}
                  select
                  disabled={isLoading || !isActive}
                  SelectProps={{
                    MenuProps: {
                      sx: { height: "300px" },
                    },
                  }}
                >
                  {INTERVAL_OPTIONS.map((option) => (
                    <MenuItem dense key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ),
          action: (
            <Typography
              onClick={() => handleIntervalSettingsCopy(day)}
              sx={{
                width: "max-content",
                fontSize: "12px",
                color: ({ palette }) => palette.primary.main,
                cursor: "pointer",
                ":hover": {
                  color: ({ palette }) =>
                    getContrastShade(palette.primary, "light"),
                },
              }}
            >
              Copy To All
            </Typography>
          ),
        },
        ...(isBurst
          ? [
              {
                id: `${day}_burst`,
                day: (
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      sx={{
                        color: ({ palette }) =>
                          isActive ? "" : alpha(palette.onSurface.main, 0.4),
                      }}
                    >
                      <Typography variant="caption">{`${day}`}</Typography>
                      <Typography
                        variant="caption"
                        fontStyle={"italic"}
                      >{` (Burst Mode)`}</Typography>
                    </Grid>
                  </Grid>
                ),
                startTime: (
                  <Controller
                    control={control}
                    name={`galleryIntervalSettings.${day}.burstTimeFrom`}
                    render={({ field }) => (
                      <TextField
                        {...baseSettingTextFieldProps}
                        sx={{
                          ...baseSettingTextFieldProps.sx,
                          width: "min-content",
                        }}
                        onChange={(e) =>
                          handleIntervalTimeChange(e.target.value, field)
                        }
                        defaultValue={getValues(
                          `galleryIntervalSettings.${day}.burstTimeFrom`,
                        )}
                        value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                        select
                        disabled={isLoading || !isActive || !isBurst}
                        SelectProps={{
                          MenuProps: {
                            sx: { height: "300px" },
                          },
                        }}
                      >
                        {INTERVAL_TIME_OPTIONS.map((option) => (
                          <MenuItem
                            dense
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ),
                finishTime: (
                  <Controller
                    control={control}
                    name={`galleryIntervalSettings.${day}..burstTimeTo`}
                    render={({ field }) => (
                      <TextField
                        {...baseSettingTextFieldProps}
                        sx={{
                          ...baseSettingTextFieldProps.sx,
                          width: "min-content",
                        }}
                        onChange={(e) =>
                          handleIntervalTimeChange(e.target.value, field)
                        }
                        value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                        select
                        disabled={isLoading || !isActive || !isBurst}
                        SelectProps={{
                          MenuProps: {
                            sx: { height: "300px" },
                          },
                        }}
                      >
                        {INTERVAL_TIME_OPTIONS.map((option) => (
                          <MenuItem
                            dense
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ),
                interval: (
                  <Controller
                    control={control}
                    name={`galleryIntervalSettings.${day}.burstTimeBetweenShots`}
                    render={({ field }) => (
                      <TextField
                        {...baseSettingTextFieldProps}
                        sx={{
                          ...baseSettingTextFieldProps.sx,
                          width: "min-content",
                        }}
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value || BURST_INTERVAL_OPTIONS[0].value}
                        select
                        disabled={isLoading || !isActive || !isBurst}
                        SelectProps={{
                          MenuProps: {
                            sx: { height: "300px" },
                          },
                        }}
                      >
                        {BURST_INTERVAL_OPTIONS.map((option) => (
                          <MenuItem
                            dense
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ),
                action: <></>,
              },
            ]
          : []),
      ];
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control, isLoading, intervalSettingsRenderTrigger]);

  const breadcrumbs = useMemo(() => {
    if (!initGallery) {
      return [];
    }

    return [
      {
        label: "Galleries",
        path: "/galleries",
      },
      isEdit
        ? {
            label: initGallery.galleryName || initGallery.id?.toString() || "",
          }
        : {
            label: "New",
          },
    ];
  }, [initGallery, isEdit]);

  const isFirebaseGallery = useMemo(() => {
    return galleryImageHost === "0";
  }, [galleryImageHost]);

  const subFramesTableHeaderActions = useMemo(() => {
    return (
      <>
        <SettingsTableHeaderButton
          onClick={() => {
            navigate({
              pathname: `../new`,
              search: createSearchParams({
                galleryId: `${initGallery?.id}`,
              }).toString(),
            });
          }}
        >
          New Sub Frame
        </SettingsTableHeaderButton>
      </>
    );
  }, [initGallery]);

  const handleIntervalTimeChange = (value, field) => {
    const isTimeFrom = field.name.match(/timeFrom$/i);
    const isBurst = field.name.match(/burst/);

    let isPopup = false;

    if (isTimeFrom) {
      isPopup =
        value >
        getValues(
          field.name.replace(
            isBurst ? "burstTimeFrom" : "timeFrom",
            isBurst ? "burstTimeTo" : "timeTo",
          ),
        );
    } else {
      isPopup =
        value <
        getValues(
          field.name.replace(
            isBurst ? "burstTimeTo" : "timeTo",
            isBurst ? "burstTimeFrom" : "timeFrom",
          ),
        );
    }

    if (isPopup) {
      setConfirmationModalParams({
        isOpen: true,
        title: isTimeFrom
          ? "Start time is later than the finish time"
          : "Finish time is earlier than the start time",
        content: `This means the finish time will be treated as the next day. Are you sure?`,
        onDone: () => {
          field.onChange(value);
        },
      });
    } else {
      field.onChange(value);
    }
  };

  const handleGalleryRemove = async () => {
    setIsLoading(true);

    if (initGallery) {
      await getFirebaseController()
        .Gallery.deleteGallery(initGallery.id)
        .then((status) => {
          if (status) {
            navigate("/galleries");

            setSnackbarProps({
              open: true,
              content: `Gallery deleted successfully!`,
            });
          } else {
            setSnackbarProps({
              open: true,
              content: `Gallery delete failed!`,
              severity: "error",
            });
          }
        });
    }

    setIsLoading(false);
  };

  const initFrame = async (galleryId, isNewGallery: boolean = false) => {
    setIsCropImageLoaded(false);

    const gallery = await getFirebaseController().Gallery.getGallery(galleryId);

    if (gallery) {
      const url = await getGalleryThumbnail(gallery);

      if (url) {
        if (cropImageRef.current) {
          initCropImage();
        }

        setCropImageUrl(url);
        setCrop({
          unit: "%",
          ...(isNewGallery
            ? getDefaultGallery().cropPosition
            : gallery.cropPosition),
        });
      }
    }
  };

  const initCropImage = () => {
    if (cropImageRef.current) {
      const { width, height } = cropImageRef.current;

      const { unit, ...pixelCrop } = convertToPixelCrop(crop, width, height);

      const round = roundCropPosition(pixelCrop);

      setIsCropImageLoaded(true);

      setValue("temp._pixelCropPosition", round);
      setValue("temp._cropImageDimension", { width, height });
    }
  };

  useEffect(() => {
    if (cropImageRef.current) {
      initCropImage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropImageRef.current]);

  useEffect(() => {
    // use callback to prevent rerender on every field changed

    const subscription = watch((value, props) => {
      if (props.name === "gallery.galleryName") {
        setGalleryName(value.gallery.galleryName);
        handleAutoHostDirectory(value);
      } else if (props.name === "gallery.active" && props.type === "change") {
        setValue("gallery.archived", !value.gallery.active, {
          shouldDirty: true,
        });
      } else if (props.name === "gallery.archived" && props.type === "change") {
        setValue("gallery.active", !value.gallery.archived, {
          shouldDirty: true,
        });
      } else if (props.name === "gallery.galleryImageHost") {
        setGalleryImageHost(value.gallery.galleryImageHost);
        setValue("_temp.host_directory_prefix", "/");
        handleAutoHostDirectory(value);
      } else if (
        props.type === "change" &&
        (props.name === "gallery.pinnedLatitude" ||
          props.name === "gallery.pinnedLongitude")
      ) {
        const { pinnedLatitude, pinnedLongitude } = value.gallery;

        if (pinnedLatitude && pinnedLongitude) {
          setMarkerPositionDebounce([pinnedLatitude, pinnedLongitude]);
        }
      } else if (props.name === "gallery.assignedDevice") {
        const assignedDevice = value.gallery.assignedDevice;

        if (assignedDevice) {
          handleAssignedDeviceChanged(assignedDevice);
        } else {
          setIsShowDeviceSettings(false);
        }
      } else if (
        props.name?.match(/^galleryIntervalSettings.\w+.(active|burst)$/)
      ) {
        setIntervalSettingsRenderTrigger((prev) => !prev);
      } else if (
        props.name?.match("deviceSettings.triggerTwoStep") &&
        props.type === "change"
      ) {
        const triggerTwoStep = value.deviceSettings.triggerTwoStep;

        if (triggerTwoStep) {
          setValue("deviceSettings.manualFocusDistance", -1, {
            shouldDirty: true,
          });

          setValue("_temp.isForcedFocus", false);
        }
      } else if (props.name?.match("deviceSettings.manualFocusDistance")) {
        const currentValue = value.deviceSettings.manualFocusDistance;

        if (currentValue > -1) {
          cacheManualFocusDistanceRef.current =
            value.deviceSettings.manualFocusDistance;
        }
      } else if (props.name?.match("_temp.isForcedFocus")) {
        const isForcedFocus = value._temp.isForcedFocus;

        setIsForcedFocus(isForcedFocus);

        if (isForcedFocus) {
          setValue(
            "deviceSettings.manualFocusDistance",
            cacheManualFocusDistanceRef.current,
            {
              shouldDirty: true,
            },
          );

          setValue("deviceSettings.triggerTwoStep", false, {
            shouldDirty: true,
          });
        } else {
          setValue("deviceSettings.manualFocusDistance", -1, {
            shouldDirty: true,
          });
        }
      } else if (props.name?.match("_temp.client")) {
        const options = initJobSiteOptions(value._temp.client);

        const site =
          options.find((o) => o.value === value.gallery.jobSite) || options[0];

        if (!site) {
          setValue("gallery.jobSite", null, { shouldDirty: true });
        } else {
          setValue("gallery.jobSite", site.value, { shouldDirty: true });
        }

        handleAutoHostDirectory(value);
      } else if (props.name === "gallery.jobSite") {
        // getUsersList(value.gallery.jobSite);
        getGalleryList(value.gallery.jobSite);

        handleAutoHostDirectory(value);

        setValue("_temp.accessibleUsers", [], { shouldDirty: true });

        getUsersList(value.gallery.jobSite).then(
          ([users, userToUserRoleMapper]) =>
            handleAccessibleUsers(
              users,
              userToUserRoleMapper,
              value.gallery.jobSite,
            ),
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, params, isSubFrame]);

  useEffect(() => {
    if (galleryImageHost !== "0") {
      const value = getValues("gallery.assignedDevice");
      if (value && value !== tempAssignedDeviceId) {
        setTempAssignedDeviceId(value);
      }

      setValue("gallery.assignedDevice", null);
    } else {
      setValue("gallery.assignedDevice", tempAssignedDeviceId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryImageHost]);

  useEffect(() => {
    setIsLoading(true);

    getDeviceList();

    const parentGalleryIdParam = searchParams.get("galleryId");

    if (isEdit) {
      initGalleryEdit().then(() => setIsLoading(false));
    } else if (parentGalleryIdParam) {
      const parentGalleryId = Number(parentGalleryIdParam);
      // create new subframe
      initGalleryEdit(parentGalleryId, true).then(() => {
        setValue("gallery.parentId", parentGalleryId);
        setValue("gallery.isSubFrame", true);
        setValue("gallery.cropPosition", getDefaultGallery().cropPosition);

        initFrame(parentGalleryId, true);

        setIsSubFrame(true);
        setIsLoading(false);
      });
    } else {
      initGalleryNew();

      setIsLoading(false);
    }

    return () => {
      setIsSubFrame(false);
      setParentGallery(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Box>
      <Navbar title="Gallery Dashboard" />

      {initGallery && (
        <ConfirmationModal
          title={`Remove this gallery?`}
          message={`Are you sure you want to remove gallery ${initGallery.galleryName}? This process cannot be undone.`}
          open={galleryRemoveModalParams.isOpen}
          onDone={() => handleGalleryRemove()}
          onClose={() =>
            setGalleryRemoveModalParams({
              isOpen: false,
            })
          }
        />
      )}

      <SettingConfirmationModal
        {...confirmationModalParams}
        onClose={() => {
          setConfirmationModalParams((prev) => {
            return {
              ...prev,
              isOpen: false,
              title: "",
              content: "",
            };
          });
        }}
      />

      <DashboardEditContainer
        breadcrumbs={breadcrumbs}
        title={
          isEdit
            ? initGallery?.galleryName || "-"
            : `New ${isSubFrame ? "Sub-Frame Gallery" : "Gallery"} ${
                galleryName ? `- ${galleryName}` : ""
              }`
        }
        subtitle="Fill in the gallery's info and press save when done."
        onSave={handleSubmit(handleSave, (errors) => {
          if (
            _.get(errors, "gallery.galleryName") ||
            _.get(errors, "_temp.client") ||
            _.get(errors, "gallery.jobSites")
          ) {
            setSnackbarProps({
              open: true,
              content: `Gallery name, client and job site cannot be empty.`,
              severity: "error",
            });
          } else if (
            _.get(errors, "gallery.pinnedLatitude") ||
            _.get(errors, "gallery.pinnedLongitude")
          ) {
            setSnackbarProps({
              open: true,
              content: `Invalid latitude or longitude.`,
              severity: "error",
            });
          }
        })}
        onDelete={
          isEdit
            ? () => {
                setGalleryRemoveModalParams({
                  isOpen: true,
                });
              }
            : undefined
        }
        isLoading={isLoading}
      >
        <Grid container direction="column" wrap="nowrap" gap={4}>
          <Grid item>
            <SettingsTable
              title={"Gallery Settings"}
              rows={gallerySettingsRow}
              columns={columns}
              headerActions={
                isEdit && initGallery ? (
                  <>
                    <CopyLinkButton
                      copyValue={getFirebaseController().getFunctionUrl(
                        `latestImage/galleries/${initGallery.id}`,
                      )}
                      disabled={isLoading}
                      color="secondary"
                      endIcon={<LinkIcon />}
                      sx={{
                        color: ({ palette }) => palette.onSecondary.main,
                        border: ({ palette }) =>
                          alpha(palette.onSecondary.main, 0.3),
                        mr: 1,
                      }}
                    >
                      Latest Image
                    </CopyLinkButton>

                    <LinkWrapper to={`image-viewer`}>
                      <Button
                        disabled={isLoading}
                        variant="contained"
                        size="small"
                        disableElevation
                        sx={{
                          fontSize: 12,
                          color: ({ palette }) => palette.onPrimary.main,
                          textTransform: "none",
                          border: ({ palette }) =>
                            alpha(palette.onPrimary.main, 0.3),
                        }}
                        endIcon={
                          <OpenInNew sx={{ fontSize: "14px !important" }} />
                        }
                      >
                        Go To Gallery
                      </Button>
                    </LinkWrapper>
                  </>
                ) : (
                  <></>
                )
              }
            />
          </Grid>

          {isSubFrame && (
            <>
              <Grid item>
                <SettingsTable
                  title={"Frame"}
                  rows={frameSettingsRow}
                  columns={columns}
                />
              </Grid>
            </>
          )}

          {!isSubFrame && (
            <>
              <Grid item>
                <SettingsTable
                  title={"Location"}
                  rows={locationSettingsRow}
                  columns={columns}
                />
              </Grid>

              {isFirebaseGallery && (
                <>
                  <Grid item>
                    <SettingsTable
                      title={"Device Settings"}
                      rows={deviceSettingRows}
                      columns={columns}
                    />
                  </Grid>

                  <Grid item>
                    <SettingsTable
                      title={"Shooting Times"}
                      rows={intervalSettingsRows}
                      columns={intervalSettingsColumns}
                    />
                  </Grid>
                </>
              )}

              {isEdit && (
                <Grid item>
                  <SettingsTable
                    headerActions={subFramesTableHeaderActions}
                    title={"Sub-Frame Galleries"}
                    rows={subFramesRow}
                    columns={subFramesColumns}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DashboardEditContainer>
    </Box>
  );
};

export default GalleryEditWindow;
