import { useState, useEffect, useRef, useMemo, Fragment } from "react";

import { Box, Button, useTheme } from "@mui/material";
import { faDroplet } from "@fortawesome/free-solid-svg-icons";

import {
  Document,
  Page,
  Text,
  View,
  pdf,
  Font,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

import { momentWithTimeZoneHOF } from "hooks/useTimeZone";

import {
  AggregatedWeatherDayData,
  AggregatedWeatherHourData,
  FaWeatherIconName,
  getFaWeatherColor,
  getFaWeatherIcon,
  testHourData,
  testWeatherData,
} from "hooks/useWeather";

import { getDisplayValue, getDisplayValueWithUnit } from "utils/display";

import { Thumbnail, Image as ImageType } from "database/ImageHandlerV2";
import { FaPdfIcon } from "components/Icons/PdfIcon";
import LineChart, { LineChartOptions } from "components/Charts/LineChart";

import moment from "moment";
import { toPng } from "html-to-image";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf",
      fontWeight: 300,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf",
      fontWeight: 500,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
      fontWeight: 700,
      fontStyle: "normal",
    },
  ],
});

const colors = {
  secondary: "#fafafa",
  surface: "#ffffff",
  onSurface: "#383838",
  info: "#057b97",
  divider: "#e0e0e0",
  border: "#e6e6e6",
  disabled: "#dcdcdc",
};

const fontSizes = {
  header: 16,
  title: 12,
  subtitle: 10,
  body: 8,
  caption: 6,
};

const textBaseStyles = {
  fontFamily: "Roboto",
  fontSize: fontSizes.body,
};

export const WeatherPdf = ({
  chartImage,
  dayData,
  hoursData,
  title,
  thumbnailSrc,
  thumbnailDateTime,
  timeZone = "UTC",
  stations = [],
  hourlyDataThumbnails,
}: {
  chartImage: string;
  dayData: AggregatedWeatherDayData;
  hoursData: AggregatedWeatherHourData[];
  title: string;
  thumbnailSrc: string;
  thumbnailDateTime: string;
  timeZone?: string;
  stations?: string[];
  hourlyDataThumbnails: { [hour: string]: Thumbnail<ImageType>[] };
}) => {
  const momentTz = useMemo(() => {
    return momentWithTimeZoneHOF(timeZone);
  }, [timeZone]);

  const info = [
    [
      {
        label: "Wind Speed",
        value: `${getDisplayValue(
          dayData?.wzData._max_wind_speed,
          (value) => `${value} Km/h (${(value / 3.6).toFixed(1)} m/s)`,
        )}`,
      },
      {
        label: "Wind Gust",
        value: `${getDisplayValue(
          dayData?.wzData._max_wind_gust,
          (value) => `${value} Km/h (${(value / 3.6).toFixed(1)} m/s)`,
        )}`,
      },
    ],
    [
      {
        label: "Rain To 9am",
        value: getDisplayValueWithUnit(dayData?.wzData?.rainfall, "mm"),
      },
      {
        label: "Solar Energy",
        value: getDisplayValue(dayData?.wzData?.insolation, (solarenergy) => {
          return `${(Number(solarenergy) * 277.78).toFixed(2)} Wh/m²`;
        }),
      },
    ],
    [
      {
        label: "Sun Rise",
        value: getDisplayValue(dayData?.vcData?.sunrise, (value) =>
          moment(value, "HH:mm:ss").format("HH:mm"),
        ),
      },
      {
        label: "Sun Set",
        value: getDisplayValue(dayData?.vcData?.sunset, (value) =>
          moment(value, "HH:mm:ss").format("HH:mm"),
        ),
      },
    ],
    [
      {
        label: "Time Zone",
        value: `${timeZone}`,
      },
      {
        label: "Offset",
        value: `${dayData ? momentTz().format("Z z") : "-"}`,
      },
    ],
    [
      {
        label: "Station Used",
        value: stations.map((s) => s).join(", "),
      },
    ],
  ];

  return (
    <Document>
      <Page
        size="A4"
        style={{
          flexDirection: "column",
          backgroundColor: colors.surface,
          padding: 10,
          ...textBaseStyles,
        }}
      >
        <View
          style={{
            border: `1px solid ${colors.divider}`,
            borderRadius: 2,
            color: colors.onSurface,
          }}
        >
          <View
            style={{
              backgroundColor: colors.info,
              color: "white",
              padding: 12,
              fontSize: fontSizes.header,
              fontWeight: "bold",
              borderTopLeftRadius: 2,
              borderTopRightRadius: 2,
            }}
          >
            <Text>{title}</Text>
          </View>
          <View
            style={{
              backgroundColor: colors.info,
              color: "white",
              padding: 12,
              fontSize: fontSizes.subtitle,
              fontWeight: "bold",
              // borderTopLeftRadius: 2,
              // borderTopRightRadius: 2,
            }}
          >
            <Text>
              {`Summary - ${moment(dayData?.wzData?.date).format(
                "ddd, MMM DD, YYYY",
              )}`}
            </Text>
          </View>

          <View
            style={{
              padding: 10,
              paddingBottom: 0,
              color: colors.onSurface,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 16,
              }}
            >
              <View style={{ flex: 1 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    alignItems: "flex-end",
                  }}
                >
                  <View>
                    <FaPdfIcon
                      faIcon={getFaWeatherIcon(
                        dayData?.vcData?.icon as FaWeatherIconName,
                      )}
                      style={{
                        color: getFaWeatherColor(
                          dayData?.vcData?.icon as FaWeatherIconName,
                        ),
                        width: 55,
                      }}
                    />
                  </View>

                  <View>
                    <Text
                      style={{
                        fontSize: fontSizes.title,
                        fontWeight: "bold",
                        lineHeight: 1,
                      }}
                    >
                      {getDisplayValueWithUnit(dayData?.wzData?.max, "°C")}
                    </Text>

                    <Text
                      style={{
                        marginTop: 6,
                        fontSize: fontSizes.body,
                        fontWeight: "bold",
                      }}
                    >
                      {getDisplayValue(dayData?.vcData?.conditions)}
                    </Text>

                    <Text style={{ fontSize: fontSizes.caption }}>
                      {getDisplayValue(dayData?.vcData?.description)}
                    </Text>
                  </View>
                </View>

                <View style={{ width: "100%" }}>
                  {info.map((row, rowIndex) => {
                    return (
                      <Fragment key={rowIndex}>
                        <View
                          style={{
                            borderBottomColor: colors.divider,
                            borderBottomWidth: 1,

                            margin: "8px 0",
                          }}
                        />

                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            gap: 32,
                          }}
                        >
                          {row.map((data, dataIndex) => {
                            return (
                              <View
                                key={dataIndex}
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  flex: 1,
                                  gap: 6,
                                }}
                              >
                                <Text style={{ fontSize: fontSizes.body }}>
                                  {data.label}
                                </Text>

                                <Text
                                  style={{
                                    fontSize: fontSizes.body,
                                    fontWeight: "bold",
                                    //   textAlign: "end",
                                  }}
                                >
                                  {data.value}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                      </Fragment>
                    );
                  })}
                </View>
              </View>

              {thumbnailSrc && (
                <View
                  style={{
                    marginTop: "12px",
                    textAlign: "center",
                    width: "40%",
                    borderRadius: 4,
                    padding: 6,
                    backgroundColor: colors.secondary,
                    border: `1px solid ${colors.border}`,
                  }}
                >
                  <Image
                    src={thumbnailSrc}
                    style={{
                      borderRadius: 2,
                    }}
                  />

                  <Text style={{ marginTop: 4, lineHeight: 0 }}>
                    {thumbnailDateTime}
                  </Text>
                </View>
              )}
            </View>

            <View style={{ marginVertical: 12 }}>
              <Image
                src={chartImage}
                style={{
                  height: "auto",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </View>
          </View>

          <View break>
            <View
              style={{
                backgroundColor: colors.info,
                color: "white",
                paddingHorizontal: 12,
                paddingVertical: 4,

                fontSize: fontSizes.title,
                fontWeight: "bold",
                borderTopLeftRadius: 2,
                borderTopRightRadius: 2,
              }}
            >
              <Text>{title}</Text>
            </View>
            <View
              style={{
                backgroundColor: colors.info,
                color: "white",
                paddingHorizontal: 12,
                paddingVertical: 4,
                fontSize: fontSizes.subtitle,
                fontWeight: "bold",
              }}
            >
              <Text>
                {`Hourly - ${moment(dayData?.wzData?.date).format(
                  "ddd, MMM DD, YYYY",
                )}`}
              </Text>
            </View>

            <View style={{ backgroundColor: colors.secondary, padding: 10 }}>
              {hoursData.map((data, dataIndex) => {
                const thumbnails =
                  hourlyDataThumbnails[
                    moment.parseZone(data.wzData.local_time).format("HH")
                  ];

                const isDisabled = !thumbnails || thumbnails.length === 0;

                return (
                  <View
                    key={data.wzData.local_time}
                    wrap={false}
                    style={{
                      backgroundColor: isDisabled
                        ? colors.disabled
                        : colors.surface,

                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      border: "1px solid #e6e6e6",
                      borderRadius: 4,
                      marginTop: dataIndex === 0 ? 0 : 4,
                      padding: 4,
                      paddingLeft: 8,
                      fontSize: fontSizes.body,
                      lineHeight: 0,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 4,
                        flex: 1,
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>
                        {moment(data.vcData.datetime, "HH:mm").format("HH:mm")}
                      </Text>

                      <FaPdfIcon
                        faIcon={getFaWeatherIcon(
                          dayData?.vcData?.icon as FaWeatherIconName,
                        )}
                        style={{
                          color: getFaWeatherColor(
                            dayData?.vcData?.icon as FaWeatherIconName,
                          ),
                          width: 15,
                        }}
                      />

                      <Text style={{ fontWeight: "bold" }}>
                        {getDisplayValueWithUnit(
                          data.wzData.temperature,
                          "°",
                          false,
                        )}
                      </Text>
                    </View>

                    <View
                      style={{
                        width: "80%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 4,
                      }}
                    >
                      {[
                        {
                          value: `${getDisplayValue(
                            data.wzData.wind_direction_compass,
                          )}`,
                          icon: getFaWeatherIcon("wind"),
                          title: "Wind Direction",
                        },
                        {
                          value: `${getDisplayValue(
                            data.wzData.wind_speed,
                            (value) => {
                              return `${value} Km/h (${(value / 3.6).toFixed(
                                1,
                              )} m/s)`;
                            },
                          )}`,
                          icon: faDroplet,
                          title: "Wind Speed",
                        },
                        {
                          value: `${getDisplayValue(
                            data.wzData.wind_gust,
                            (value) => {
                              return `${value} Km/h (${(value / 3.6).toFixed(
                                1,
                              )} m/s)`;
                            },
                          )}`,
                          icon: faDroplet,
                          title: "Wind Gust",
                        },
                        {
                          value: getDisplayValueWithUnit(
                            data.wzData.rainfall_since_9am,
                            "mm",
                          ),
                          icon: faDroplet,
                          title: "Rain To 9am",
                        },
                      ].map((data) => {
                        return (
                          <View
                            key={data.title}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              flex: 1,
                              width: "100%",
                              gap: 2,
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                fontSize: fontSizes.caption,
                              }}
                            >
                              {data.title}
                            </Text>

                            <Text
                              style={{
                                textAlign: "center",
                                fontSize: fontSizes.caption,
                              }}
                            >
                              {data.value}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        </View>

        <View
          style={{
            position: "absolute",
            fontSize: fontSizes.body,
            bottom: 24,
            left: 0,
            right: 0,
            textAlign: "center",
            color: "grey",

            // marginVertical: 20,
            // textAlign: "center",
            // fontSize: fontSizes.body,
            // lineHeight: 1.5,
          }}
          fixed
        >
          <Text>
            {`Report Generated: ${moment()
              .tz(timeZone)
              .format("HH:mm A z, Do MMMM YYYY ")}`}
          </Text>

          <Text>
            Information supplied by Weatherzone based on data provided by the
            Bureau of Meteorology (BOM)
          </Text>
        </View>

        <Text
          style={{
            position: "absolute",
            fontSize: fontSizes.caption,
            bottom: 12,
            left: 0,
            right: 0,
            textAlign: "center",
            color: "grey",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const TestCharts = () => {
  const { palette } = useTheme();

  const baseOptions: Partial<LineChartOptions> = {
    xKey: "vcData.datetime",
    xLabel: `Time`,
    xInterval: 0,
    yInterval: 0,
    yDomain: [
      (dataMin) => Math.floor(dataMin - 3),
      (dataMax) => Math.floor(dataMax + 3),
    ],
    xTickFormatter: (value, i) => {
      return moment(value, "HH").format("HH");
    },
    showLegend: false,
    minHeight: 200,
  };

  const temperatureOptions = {
    ...baseOptions,
    colors: [palette.error.main],
    yLabel: "Temperature (°C)",
    yKey: "wzData.temperature",
    unit: "°C",
  };

  const currentChartData = {
    title: "Temperature Changes",
    options: temperatureOptions,
    data: testHourData,
    tabLabel: "Temperature",
  };

  return (
    <LineChart
      id={currentChartData.title}
      data={currentChartData.data}
      options={currentChartData.options}
      title={currentChartData.title}
      // onItemClick={onItemClick}
    />
  );
};

const TestPdfWindow = () => {
  const [chartImage, setChartImage] = useState("");

  const props = {
    chartImage,
    dayData: testWeatherData[0] as AggregatedWeatherDayData,
    hoursData: testHourData as AggregatedWeatherHourData[],
    title: "ADCO - Puckapunyal - Cam 01",
    thumbnailSrc: "",
    thumbnailDateTime: "a",
    stations: ["a"],
    hourlyDataThumbnails: {},
  };

  const handleSubmit = async () => {
    const blob = await pdf(<WeatherPdf {...props} />).toBlob();
    const pdfURL = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = `weather_report_${moment().format("YYYY_MM_DD_HHmmss")}.pdf`;
    a.href = pdfURL;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const ref = useRef();

  useEffect(() => {
    const generateImage = async () => {
      const node = ref.current;

      if (node) {
        const dataUrl = await toPng(node);

        setChartImage(dataUrl);
      }
    };

    generateImage();
  }, []);

  return (
    <Box>
      <Box
        ref={ref}
        id="chart"
        sx={{
          position: "absolute",
          top: 0,
          zIndex: -1,
          height: "100%",
          width: 1000,
        }}
      >
        <Box sx={{ mb: 1 }}>
          <TestCharts />
        </Box>
        <Box sx={{ mb: 1 }}>
          <TestCharts />
        </Box>
        <Box sx={{ mb: 1 }}>
          <TestCharts />
        </Box>
      </Box>

      <Box>
        <Button onClick={handleSubmit} variant="contained">
          sds
        </Button>
      </Box>

      {chartImage && (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <WeatherPdf {...props} />
        </PDFViewer>
      )}
    </Box>
  );
};

export default TestPdfWindow;
