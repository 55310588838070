import { Fragment } from "react";
import { Box, Divider, Typography } from "@mui/material";

type InputLegendProps = {
  items: { color: string; label: string; description?: string }[];
};

const InputLegend = ({ items }: InputLegendProps) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {items.map((item, itemIndex) => {
        return (
          <Fragment key={itemIndex}>
            {itemIndex > 0 && (
              <Divider
                flexItem
                orientation="vertical"
                sx={{ bgcolor: item.color }}
              />
            )}

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  bgcolor: item.color,
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                }}
              />

              <Typography
                variant="caption"
                sx={{
                  fontSize: 12,
                  lineHeight: 0,
                  fontWeight: "bold",
                  ml: 0.5,
                }}
              >
                : {item.label}
              </Typography>

              {item.description && (
                <Typography
                  sx={{
                    fontSize: "12px !important",
                    lineHeight: 1,
                    ml: 0.5,
                  }}
                >
                  ({item.description})
                </Typography>
              )}
            </Box>
          </Fragment>
        );
      })}
    </Box>
  );
};

export default InputLegend;
