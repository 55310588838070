import { Fragment, ReactNode, forwardRef, memo } from "react";

import {
  Grid,
  Typography,
  Button,
  Box,
  alpha,
  useMediaQuery,
  TextFieldProps,
  ButtonProps,
} from "@mui/material";

import _ from "lodash";
import { Theme } from "@mui/material/styles";
import tinycolor from "tinycolor2";
import { getContrastShade } from "theme/reliveItTheme";

// const getContrastShade = (color1, color2) => {
//   console.log()
// }

export interface DashboardHeaderButton {
  label: string;
  onClick?: () => void;
  isHidden?: boolean;
  component?: ReactNode;
}

export interface SortType {
  type: string;
  isAsc: boolean;
}

export interface ViewOption {
  label: string;
  onClick: () => void;
  key: string;
}

interface DashboardHeaderProps {
  sortOptions: string[];
  displayOptions?: string[];
  handleSort: (sortType: string) => void;
  handleDisplay?: (displayOption) => void;
  currentSortType: SortType;
  currentDisplayOptions?: string[];
  buttons?: DashboardHeaderButton[];
  viewOptions?: ViewOption[];
  currentViewType?: string;
  disabled?: boolean;
}

const getDashboardHeaderButtonsBaseProps = (isActive: boolean): ButtonProps => {
  return {
    color: "onSecondary",
    size: "small",
    variant: "outlined",
    sx: {
      height: "100%",
      width: "max-content",
      lineHeight: 1,
      p: 1,
      textTransform: "none",
      backgroundColor: ({ palette }) =>
        isActive ? alpha(palette.tertiary.main, 1) : null,

      color: ({ palette }) =>
        isActive
          ? palette.onTertiary.main
          : alpha(palette.onBackground.main, 0.7),

      border: ({ palette }) =>
        isActive
          ? `1px solid ${palette.onTertiary.main}`
          : `1px solid ${alpha(palette.onSecondary.main, 0.3)}`,

      ":hover": {
        background: ({ palette }) =>
          isActive
            ? alpha(palette.tertiary.main, 1)
            : alpha(getContrastShade(palette.secondary), 0.1),
        color: ({ palette }) =>
          isActive ? palette.onTertiary.main : palette.onBackground.main,
      },
    },
  };
};

const DashboardHeader = forwardRef<HTMLDivElement, DashboardHeaderProps>(
  (
    {
      sortOptions,
      displayOptions,
      handleSort,
      handleDisplay,
      currentSortType,
      currentDisplayOptions,
      buttons,
      viewOptions,
      currentViewType,
      disabled,
    },
    ref,
  ) => {
    const smallScreenAndUp = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up("sm"),
    );
    if (disabled) {
      return null;
    }

    return (
      <Grid
        ref={ref}
        container
        mb={{
          xs: 2,
          sm: 3,
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
        columnSpacing={4}
        rowSpacing={2}
        sx={{
          overflow: "hidden",
          color: ({ palette }) => palette.onBackground.main,
        }}
      >
        <Grid
          item
          sx={{
            minWidth: {
              xs: "100%",
              sm: 0,
            },
          }}
        >
          <Grid container alignItems={"center"} spacing={1}>
            {smallScreenAndUp && (
              <Grid item>
                <Typography>Sort:</Typography>
              </Grid>
            )}

            <Grid
              item
              sx={{
                flex: 1,
                overflow: "hidden",
              }}
            >
              <Grid
                container
                gap={1}
                sx={{
                  flexWrap: "nowrap !important",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  scrollbarWidth: "none",
                }}
              >
                {sortOptions.map((option, optionIndex) => {
                  const isActive = currentSortType.type === option;

                  return (
                    <Grid item key={optionIndex}>
                      <Button
                        onClick={() => handleSort(option)}
                        {...getDashboardHeaderButtonsBaseProps(isActive)}
                      >
                        {option}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {displayOptions && (
          <Grid
            item
            sx={{
              flex: 1,
              minWidth: {
                xs: "100%",
                sm: 0,
              },
            }}
          >
            <Grid container alignItems={"center"} spacing={1}>
              {smallScreenAndUp && (
                <Grid item>
                  <Typography>Show:</Typography>
                </Grid>
              )}

              <Grid
                item
                sx={{
                  flex: 1,
                  overflow: "hidden",
                }}
              >
                <Grid
                  container
                  gap={1}
                  sx={{
                    flexWrap: "nowrap !important",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                    scrollbarWidth: "none",
                  }}
                >
                  {displayOptions.map((option, optionIndex) => {
                    const isActive = currentDisplayOptions!.includes(option);

                    return (
                      <Grid item key={optionIndex}>
                        <Button
                          onClick={() => handleDisplay!(option)}
                          {...getDashboardHeaderButtonsBaseProps(isActive)}
                        >
                          {option}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {viewOptions && viewOptions.length > 0 && (
          <Grid item>
            <Box
              sx={{
                background: ({ palette }) => alpha(palette.secondary.dark, 0.5),
                borderRadius: 1,
              }}
            >
              {viewOptions.map((optionButton, optionButtonIndex) => {
                const isActive = currentViewType === optionButton.key;

                return (
                  <Fragment key={optionButton.key}>
                    <Button
                      onClick={optionButton.onClick}
                      size="small"
                      sx={{
                        textTransform: "none",
                        fontWeight: isActive ? "bold" : "normal",
                        color: ({ palette }) =>
                          isActive
                            ? palette.onSecondary.main
                            : alpha(palette.onSecondary.main, 0.8),

                        px: 2,
                        width: "max-content",
                      }}
                    >
                      {optionButton.label}
                    </Button>

                    {optionButtonIndex !== viewOptions.length - 1 && (
                      <Typography
                        display={"inline"}
                        sx={{
                          color: ({ palette }) =>
                            alpha(palette.onSecondary.main, 0.9),
                        }}
                      >
                        |
                      </Typography>
                    )}
                  </Fragment>
                );
              })}
            </Box>
          </Grid>
        )}

        {buttons?.map((button, buttonIndex) => {
          return (
            <Fragment key={buttonIndex}>
              {!button.isHidden && (
                <Grid
                  item
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "max-content",
                    },
                  }}
                >
                  {button.component ? (
                    button.component
                  ) : (
                    <Button
                      disableRipple
                      disableElevation
                      variant="contained"
                      size="small"
                      color="onBackground"
                      onClick={button.onClick}
                      sx={{
                        width: "100%",
                        display: "block",
                        px: 2,
                        textTransform: "none",
                        color: ({ palette }) => palette.background.default,
                      }}
                    >
                      {button.label}
                    </Button>
                  )}
                </Grid>
              )}
            </Fragment>
          );
        })}
      </Grid>
    );
  },
);

export default memo(DashboardHeader, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});
