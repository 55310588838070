import { Firestore, query, where } from "firebase/firestore";

import { FirebaseController } from "database/FirebaseController";

import { getDefaultUserRole, getDefaultUser } from "database/DataDefaultValues";
import { RoleAccessLevel, User, UserRole } from "database/DataTypes";
import _ from "lodash";
import { promise } from "zod";

type GetUserRolesOptions = {
  ids?: number[];
  clientIds?: number[];
  jobSiteIds?: number[];
  accessLevels?: RoleAccessLevel[];
  userIds?: number[];
};

export class _UserController {
  private parent!: FirebaseController;
  private db!: Firestore;

  constructor(parent: FirebaseController) {
    this.parent = parent;
    this.db = this.parent.getDb();
  }

  async getUser(userId) {
    return this.parent.getDocumentWithId<User>("users", userId);
  }

  async getUsers(userIds?: string[] | number[]) {
    return this.parent.getDocuments<User>("users", userIds);
  }

  async getUserRole(roleId) {
    return this.parent.getDocumentWithId<UserRole>("userRoles", roleId);
  }

  async getUserRoles(options: GetUserRolesOptions = {}) {
    const queries = [
      ...(!_.isUndefined(options.ids) ? [where("id", "in", options.ids)] : []),

      ...(!_.isUndefined(options.clientIds)
        ? [where("associatedClient", "in", options.clientIds)]
        : []),

      ...(!_.isUndefined(options.accessLevels)
        ? [where("accessLevel", "in", options.accessLevels)]
        : []),

      ...(!_.isUndefined(options.userIds)
        ? [where("associatedUser", "in", options.userIds)]
        : []),

      ...(!_.isUndefined(options.jobSiteIds)
        ? [
            where(
              "accessableJobSites",
              "array-contains-any",
              options.jobSiteIds,
            ),
          ]
        : []),
    ];

    const userRoles = await this.parent.getDocumentListWithQuery<UserRole>(
      query(this.parent.getColRef("userRoles"), ...queries),
    );

    return userRoles;
  }

  async getUserRolesByUserId(userId: number) {
    return this.parent.getDocumentListWithValue<UserRole>(
      "userRoles",
      "associatedUser",
      userId,
    );
  }

  async getUserRolesByClientId(clientId: number) {
    return this.parent.getDocumentListWithValue<UserRole>(
      "userRoles",
      "associatedClient",
      clientId,
    );
  }

  async addUserRole(userRole: Partial<UserRole>) {
    const userRoleId = userRole.id || this.parent.getNewDocumentId();

    const newUserRole: UserRole = {
      ...getDefaultUserRole(),
      ...userRole,
      id: userRoleId,
    };

    return await this.parent.createOrUpdateDocument(
      newUserRole,
      "userRoles",
      userRoleId,
    );
  }

  async updateUserRole(userRoleId: number, userRole: Partial<UserRole>) {
    return await this.parent.createOrUpdateDocument(
      userRole,
      "userRoles",
      userRoleId,
    );
  }

  async revokeUsersGalleryAccess(userIds: number[], galleryId: number) {
    return await this.getUserRoles({ userIds }).then(async (roles) => {
      if (roles) {
        const promises: Promise<any>[] = [];

        roles.forEach((role) => {
          promises.push(
            this.updateUserRole(role.id as number, {
              restrictedGalleries: [
                ...(role.restrictedGalleries || []),
                galleryId,
              ],
            }),
          );
        });

        return await Promise.all(promises);
      }
    });
  }

  async grandUserRoleGalleryAccess(
    roleIds: number[],
    galleryId: number,
    jobSiteId: number,
  ) {
    const promises: Promise<any>[] = [];

    roleIds.forEach((roleId) => {
      promises.push(
        this.getUserRole(roleId).then(async (role) => {
          if (role) {
            return this.updateUserRole(role.id as number, {
              restrictedGalleries: (role.restrictedGalleries || []).filter(
                (id) => id !== galleryId,
              ),
              accessableJobSites: jobSiteId
                ? _.uniq([...role.accessableJobSites, jobSiteId])
                : role.accessableJobSites,
            });
          }
        }),
      );
    });

    return await Promise.all(promises);
  }

  async revokeUserRoleGalleryAccess(roleIds: number[], galleryId: number) {
    const promises: Promise<any>[] = [];

    roleIds.forEach((roleId) => {
      promises.push(
        this.getUserRole(roleId).then(async (role) => {
          if (role) {
            return await this.updateUserRole(role.id as number, {
              restrictedGalleries: [
                ...(role.restrictedGalleries || []),
                galleryId,
              ],
            });
          }
        }),
      );
    });

    return await Promise.all(promises);
  }

  async grandUsersGalleryAccess(userIds: number[], galleryId: number) {
    return await this.getUserRoles({ userIds }).then(async (roles) => {
      if (roles) {
        const promises: Promise<any>[] = [];

        roles.forEach((role) => {
          promises.push(
            this.updateUserRole(role.id as number, {
              restrictedGalleries: (role.restrictedGalleries || []).filter(
                (id) => id !== galleryId,
              ),
            }),
          );
        });

        return await Promise.all(promises);
      }
    });
  }

  async updateUser(
    userId: number,
    user: Partial<User>,
    userRoleId: number,
    userRole: Partial<UserRole>,
  ) {
    if (user) {
      await this.parent.createOrUpdateDocument(user, "users", userId);
    }

    if (userRoleId && !_.isEmpty(userRole)) {
      await this.updateUserRole(userRoleId, userRole);
    }
  }

  async createUser(
    user: Partial<User>,
    userRole: Partial<UserRole>,
    password: string,
  ) {
    const newUser = {
      ...getDefaultUser(),
      ...user,
      id: user.id || this.parent.getNewDocumentId(),
    };

    const newUserRole = {
      ...getDefaultUserRole(),
      ...userRole,
      associatedUser: newUser.id,
      id: userRole.id || this.parent.getNewDocumentId(),
    };

    return await this.parent.Callable.createAuthAndUser({
      user: newUser,
      userRole: newUserRole,
      password,
    });
  }

  async deleteUser(userId) {
    return await this.parent.Callable.deleteAuthAndUser({
      id: userId,
    });
  }

  async deleteUserRole(userRoleId: number) {
    return await this.parent.deleteDocument("userRoles", userRoleId);
  }
}
